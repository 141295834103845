import React, { useState } from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Line,
  ComposedChart,
} from "recharts";
import FilterSelect from "../../components/select/FilterSelect";

const MonthlyFloatingBarChart = ({ monthData, savingsData }) => {

  const firstMonth = Object.keys(savingsData)[0] || "Apr"; 
  const [selectedMonth, setSelectedMonth] = useState(firstMonth);


  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const monthMap = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const currentYear = new Date().getFullYear();
  const daysInSelectedMonth = getDaysInMonth(
    monthMap[selectedMonth],
    currentYear
  );

  const labels = Array.from({ length: daysInSelectedMonth }, (_, i) => i + 1);

  // Updated chartData to handle totalPxCost properly for line chart
  const chartData = labels
    .map((day) => {
      const entry = savingsData[selectedMonth]?.data.find((d) => d.day === day);
      let total = 0;
      if (entry && Array.isArray(entry.maxslot)) {
        entry.maxslot.forEach((slot) => {
          total += Math.round(slot?.totalPxCost) ?? 0;
        });

     
        return entry.maxslot.map((slot, index) => ({
          name: day,
          base: [0, 24],
          shaded: [
            parseInt(slot.start.split(":")[0], 10),
            parseInt(slot.end.split(":")[0], 10),
          ],
          totalPxCost: Math.round(slot?.totalPxCost) ?? 0,
          totalCharge: (slot?.totalDifference) ?? 0,
          total: total ?? 0, // Ensure 'total' is never undefined
          slotIndex: index + 1,
          duration: slot.duration,
          hours: [slot.start, slot.end],
        }));
      }

   
      // Default to zero values when no data is present
      return {
        name: day,
        base: [0, 24],
        shaded: [0, 0],
        totalPxCost: 0,
        totalCharge: 0,
        total: 0, // Explicitly set 'total' to 0
      };
    })
    .flat();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { hours, totalCharge, totalPxCost, duration, total } =
        payload[0].payload;
      return (
        <div className="bg-white border border-gray-300 p-2 shadow-md rounded">
          <p className="text-sm font-semibold text-[green]">Day: {label}</p>
          <p className="text-sm">
            Max trading window time: {hours[0]} - {hours[1]}
          </p>
          <p className="text-sm">
            Total savings (in Rs.) for slot: ₹{totalPxCost}{" "}
          </p>
          <p className="text-sm">Duration: {duration} hrs </p>
          <p className="text-sm">Per unit savings: { (totalCharge / (duration* 4)).toFixed(2)} Rs/unit</p>
          <p className="text-sm">Total savings (in Rs.) for day: ₹{total} </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="shadow-[0_4px_4px_0_#00000040] w-full relative p-4 border border-[#00000040] rounded-xl sm:min-h-[100px] p-5">
      <div className="sm:absolute flex justify-start left-0 ml-[4%] sm:left-[3%] items-center gap-4">
        <div className="min-w-[110px]">Select Month: </div>
        <div className="lg:mt-0 sm:w-full mr-6 lg:mr-4">
          <FilterSelect
            label={"Month"}
            height={"30px"}
            onChange={(e) => setSelectedMonth(e.target.value)}
            value={selectedMonth}
            options={monthData?.map((month) => ({
              label: month,
              value: month,
            }))}
          />
        </div>
      </div>

      <div className="flex-grow w-full sm:pl-[2%] sm:pr-[2%] sm:mb-[100px] py-1 h-[45vh] sm:flex justify-center mt-10">
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              type="number"
              domain={[0, daysInSelectedMonth]}
              tickCount={daysInSelectedMonth + 1}
            />
            <YAxis
              yAxisId="left"
              type="number"
              orientation="left"
              domain={[0, 24]}
              tick={{ fontSize: 15 }}
              label={{
                value: "Hours",
                angle: -90,
                offset: 10,
                position: "insideLeft",
              }}
              tickCount={25}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              type="number"
              tick={{ fontSize: 12 }}
              label={{
                value: "Savings (₹)",
                angle: -90,
                position: "insideRight",
                offset: 10,
                dy: -80,
              }}
            />

            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ margin: "-1rem" }} />

            {/* Shaded Slot Bar */}
            <Bar
              yAxisId="left"
              dataKey="shaded"
              fill="green"
              radius={[10, 10, 10, 10]}
              barSize={12}
              name="Max trading window for day"
            />

            {/* Line Chart for Total PX Cost */}
            <Line
              yAxisId="right"
              dataKey="total"
              strokeWidth={2}
              dot={{ r: 1 }}
              name="Total savings (in Rs.)"
              isAnimationActive={true}
              type="monotone" // Add this line to make the line curve

            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MonthlyFloatingBarChart;
