import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";

const AddHoliday = ({ holidayList, addHoliday, removeHoliday, setHolidayList, addHolidayError }) => {
    const handleDateChange = (date, index) => {
        if (!date) return;
    
        // Convert to UTC format
        const formattedDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        )).toISOString();
    
        const newList = [...holidayList];
        newList[index].date = formattedDate;
        setHolidayList(newList);
    };
    const handleNoteChange = (event, index) => {
        const newList = [...holidayList];
        newList[index].note = event.target.value;
        setHolidayList(newList);
    };

    return (
        <div className='w-[100%]'>
            <div className='font-normal text-[#323232] text-[20px] leading-[21px] mb-4'>11. Add Holidays</div>
            <div className='font-medium  text-[#706f6f] text-[16px] flex flex-row w-[60%] justify-between'>
                <div className='w-[50%]'>Date</div>
                <div className='w-[50%]'>Note</div>
            </div>
            {holidayList.map((holiday, index) => (
                <div className='mt-1' key={index}>
                    <div className='flex flex-col sm:flex-row justify-between sm:items-center'>
                        <div className='flex flex-row w-[100%]'>
                            <div className='relative sm:w-[42%] add-holiday'>
                                <DatePicker
                                    // value={holiday.date}
                                    selected={holiday.date ? new Date(holiday.date) : null}
                                    onChange={(date) => handleDateChange(date, index)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="DD/MM/YYYY"
                                />
                                <div className="absolute right-0 top-2 mt-[10px] mr-[10px]">
                                    <div>
                                        <img src="/calenderIcon.svg" className="h-[18px] w-[18px]" />
                                    </div>
                                </div>
                            </div>

                            <input
                                type="text"
                                value={holiday.note}
                                onChange={(event) => handleNoteChange(event, index)}
                                placeholder="Note"
                                className='border font-normal w-[50%] h-[50px] rounded-[20px] text-[16px] leading-[20px] border-[#B9B9B9] py-[25px] px-[20px] md:ml-[10px]'
                            />
                        </div>
                        <div className='flex flex-row w-[40%]'>
                            {holidayList.length > 1 && (<button className='text-center rounded-[7px] h-[25px] w-[25px]  text-[16px] font-medium border border-[gray] text-[red] bg-[white]' onClick={() => removeHoliday(index)}> <FontAwesomeIcon
                                icon={faXmark}
                                className=" text-[red] text-sm  font-medium"
                            />
                            </button>)}
                            {holidayList.length === index + 1 && (<button className='text-center rounded-[7px] h-[25px] w-[25px] text-[16px] font-medium leading-[24px] text-white bg-[#067C4E] mx-[10px]' onClick={addHoliday}> 
                            <FontAwesomeIcon
                                icon={faPlus}
                                className=" text-[white] text-sm font-medium"
                            />
                            </button>)}
                        </div>
                    </div>
                </div>
            ))}
            {addHolidayError ?
                <div className="text-red-500 capitalize text-[9px] px-[20px] py-[10px]">{addHolidayError}</div>
                : ""}
        </div>
    );
};

export default AddHoliday;
