import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from "recharts";
import "./px.css";
import { Calendar, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FilterSelect from "../../components/select/FilterSelect";
import CustomTooltop from "../../components/tooltips/CustomTooltop";

// Convert time to numerical hours
const convertTimeToHours = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours + minutes / 60;
};

// Generate full-day time intervals
const generateFullDayHours = () => {
  const fullDayHours = [];
  for (let i = 0; i < 24 * 4; i++) {
    let hours = Math.floor(i / 4);
    let minutes = (i % 4) * 15;
    let timeStr = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
    fullDayHours.push({
      time: convertTimeToHours(timeStr),
      formattedTime: timeStr,
      MCP: 0,
      costAtConsumerPheriphery: 0,
      differenceBetweenLandedAndDiscomCost: 0,
      totalVariableCost: 0,
    });
  }
  return fullDayHours;
};

const getMonthIndex = (month) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthIndex = monthNames.indexOf(month); // Get month index (0-based)

  if (monthIndex === -1) {
    console.error("Invalid month:", month);
    return null; // Return null for invalid month
  }

  return monthIndex;
};

// Get the first day of the month
const getFirstDayOfMonth = (month, year) => {
  const monthIndex = getMonthIndex(month);
  if (monthIndex === null) return null;
  return new Date(year, monthIndex, 1); // Return the 1st day of the given month and year
};

const getLastDayOfMonth = (month, year) => {
  const monthIndex = getMonthIndex(month);
  if (monthIndex === null) return null;
  return new Date(year, monthIndex + 1, 0); // Get the last day of the given month and year
};

const getStartAndEndOfWeek = (date) => {
  const startOfWeek = date;
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);
  return {
    start: startOfWeek,
    end: endOfWeek,
  };
};

const McpGraphChart = ({ monthData, savingsData, startDate, endDate }) => {
  const [selectedMonth, setSelectedMonth] = useState(monthData[0]); // Set first available month
  const [graphData, setGraphData] = useState(generateFullDayHours());
  const currentYear = startDate.split("/")[1];
  const firstDayOfMonth = getFirstDayOfMonth(selectedMonth, currentYear);
  const [mode, setMode] = useState("date"); // 'date', 'week', 'month'
  const [selectedDate, setSelectedDate] = useState(firstDayOfMonth);
  const startWeek = getStartAndEndOfWeek(firstDayOfMonth);
  const [selectedWeek, setSelectedWeek] = useState(startWeek);
  const [showPicker, setShowPicker] = useState(false);
  const [showMCP, setShowMCP] = useState(true);
  const [showIexTable, setShowIexTable] = useState(false);


  // Handle Date Selection
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowPicker(false);
    handleSelection(date.getDate);
  };

  // Handle Week Selection (Strict 7-Day Range)
  const handleWeekChange = (ranges) => {
    const lastDayOfMonth = getLastDayOfMonth(selectedMonth, currentYear);
    const startDate = new Date(ranges.selection.startDate);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6); // 7-day range (including start date)

    // Ensure the end date does not exceed the last day of the month
    if (endDate > lastDayOfMonth) {
      endDate.setTime(lastDayOfMonth.getTime());
    }

    setSelectedWeek({ start: startDate, end: endDate });
    setShowPicker(false);
  };

  // Format Input Display
  const getDisplayValue = () => {
    if (mode === "date") return selectedDate.toLocaleDateString("en-GB");
    if (mode === "week")
      return selectedWeek
        ? `${selectedWeek.start.toLocaleDateString(
            "en-GB"
          )} - ${selectedWeek.end.toLocaleDateString("en-GB")}`
        : "";
    if (mode === "month") {
      const firstDayOfMonth = getFirstDayOfMonth(selectedMonth, currentYear);
      const monthDisplay = selectedMonth
        ? new Date(firstDayOfMonth).toLocaleDateString("en-GB", {
            month: "long",
            year: "numeric",
          })
        : "";

      return monthDisplay;
    }

    return "";
  };

  // Handle month change
  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    const firstDayOfMonth = getFirstDayOfMonth(newMonth, currentYear);
    setMode("date");
    setSelectedMonth(newMonth);
    setSelectedDate(firstDayOfMonth); // Reset day on month change
  };

  // Handle view mode change (daily, weekly, or monthly)
  const handleViewModeChange = (e) => {
    setMode(e.target.value);
    const firstDayOfMonth = getFirstDayOfMonth(selectedMonth, currentYear);
    setSelectedMonth(selectedMonth);
    if (e.target.value === "date") {
      setSelectedDate(firstDayOfMonth); // Reset day on month change
    }
    if (e.target.value === "week") {
      console.log({ selectedMonth, mode: e.target.value });
      const weekStart = getStartAndEndOfWeek(firstDayOfMonth);
      setSelectedWeek(weekStart);
    }
  };

  // Generic function to handle both day and week selection & update graph data
  const handleSelection = (selectedRange) => {
    let dataToProcess = [];

    if (mode === "date") {
      // Process data for a single selected day
      const filteredData = savingsData[selectedMonth]?.data.find(
        (d) => d.day === selectedRange
      );

      if (filteredData) {
        dataToProcess = generateFullDayHours().map((entry) => {
          const interval = filteredData.intervals.find(
            (interval) =>
              convertTimeToHours(interval.intervalStart) === entry.time
          );

          return interval
              ? {
                    //                 MCP
                    // :
                    // 5.10971
                    // additionalSurchargeCSSCost
                    // :
                    // 190.92133333333334
                    // calculatedDiscomCharges
                    // :
                    // 84.22999999999999
                    // calculatedElectricityDuty
                    // :
                    // 112.21910133405888
                    // calculatedStuCharges
                    // :
                    // 137.57566666666665
                    // costAtConsumerPheriphery
                    // :
                    // 11.507726124574457
                    // costFordifferenceBetweenLandedAndDiscom
                    // :
                    // -371.8037272251806
                    // costforCtuPeriphery
                    // :
                    // 83.89991812503493
                    // crossSubsidySurchargeCSSCost
                    // :
                    // 251.28616666666665
                    // differenceBetweenLandedAndDiscomCost
                    // :
                    // -2.6484890933765683
                    // eneryCostGDAMRequirement
                    // :
                    // 832.2415177874518
                    // intervalEnd
                    // :
                    // "00:15"
                    // intervalStart
                    // :
                    // "00:00"
                    // landedEnergyAtConsumer
                    // :
                    // 140.38333333333333
                    // landedEnergyAtCtuPeriphery
                    // :
                    // 162.8745110363312
                    // powerConsumptionData
                    // :
                    // 140.38333333333333
                    // totalPxCost
                    // :
                    // 1496.2546844541184
                    // totalVariableCost
                    // :
                    // 8.859237031197889
                    // totalVariableRate
                    // :
                    // 9.75923703119789
                    ...entry,
                    MCP: interval.MCP ?? entry.MCP,
                    costAtConsumerPheriphery:
                        interval.costAtConsumerPheriphery ??
                        entry.costAtConsumerPheriphery,
                    differenceBetweenLandedAndDiscomCost:
                        interval.differenceBetweenLandedAndDiscomCost ??
                        entry.differenceBetweenLandedAndDiscomCost,
                    totalVariableCost:
                        interval.totalVariableCost ?? entry.totalVariableCost,

                    powerConsumptionData: interval.powerConsumptionData,
                    discomVariableCost: interval.totalVariableRate,
                    discomWheelingCharge: interval.wheelingCharges,
                    discomTodCharges: interval.todCharges,
                    totalDiscomVariableCharge: interval.totalVariableCost,

                    iexGdamPrice: interval.MCP,
                    ctuLosses: interval.ctuLoss,
                    ctuCharges: interval.ctuCharges,
                    stuLosses: interval.stuLoss,
                    stuCharges: interval.stuCharges,
                    discomWheelingLoss: interval.discomWheelingLoss,
                    discomWheelingCharges: interval.wheelingCharges,
                    crossSubsidySurchargeCSSCost: interval.crossSubsidySurcharge,
                    additionalSurchargeCSSCost: interval.additionalSurcharge,
                    electricityDuty: interval.electricityDuty,
                    schedulingOperatingCharges: interval.operatingCharge,
                    landedIexCostAtConsumerPeriphery: interval.eneryCostGDAMRequirement,    
                }
              : entry;
        });
      }
    } else if (mode === "week" || mode === "month") {
      // Define start and end of the selected period
      const startOfPeriod =
        mode === "week"
          ? new Date(selectedRange.start)
          : getFirstDayOfMonth(selectedMonth, currentYear);

      const endOfPeriod =
        mode === "week"
          ? new Date(selectedRange.end)
          : new Date(
              startOfPeriod.getFullYear(),
              startOfPeriod.getMonth() + 1,
              0
            );

      // Collect data for the entire period
      const periodData = [];
      for (
        let d = new Date(startOfPeriod);
        d <= endOfPeriod;
        d.setDate(d.getDate() + 1)
      ) {
        const dayData = savingsData[selectedMonth]?.data.find(
          (data) => data.day === d.getDate()
        );
        if (dayData) periodData.push(dayData);
      }

      const validDays = periodData.length || 1; // Avoid division by zero

      // Aggregate and calculate the average for the period
      dataToProcess = generateFullDayHours().map((entry) => {
        let aggregatedInterval = { ...entry };

        periodData.forEach((dayData) => {
          const interval = dayData.intervals.find(
            (interval) =>
              convertTimeToHours(interval.intervalStart) === entry.time
          );
          if (interval) {
            aggregatedInterval.MCP =
              (aggregatedInterval.MCP ?? 0) + (interval.MCP ?? 0);
            aggregatedInterval.costAtConsumerPheriphery =
              (aggregatedInterval.costAtConsumerPheriphery ?? 0) +
              (interval.costAtConsumerPheriphery ?? 0);
            aggregatedInterval.differenceBetweenLandedAndDiscomCost =
              (aggregatedInterval.differenceBetweenLandedAndDiscomCost ?? 0) +
              (interval.differenceBetweenLandedAndDiscomCost ?? 0);
            aggregatedInterval.totalVariableCost =
              (aggregatedInterval.totalVariableCost ?? 0) +
              (interval.totalVariableCost ?? 0);
          }
        });

        // Compute the average and prevent NaN values
        return {
          ...aggregatedInterval,
          MCP: validDays ? aggregatedInterval.MCP / validDays : 0,
          costAtConsumerPheriphery: validDays
            ? aggregatedInterval.costAtConsumerPheriphery / validDays
            : 0,
          differenceBetweenLandedAndDiscomCost: validDays
            ? aggregatedInterval.differenceBetweenLandedAndDiscomCost /
              validDays
            : 0,
          totalVariableCost: validDays
            ? aggregatedInterval.totalVariableCost / validDays
            : 0,
        };
      });
    }

    // Ensure the graph data includes the last point at 00:00
    if (!dataToProcess.find((d) => d.time === 24)) {
      const lastEntry = dataToProcess[dataToProcess.length - 1] || {};
      dataToProcess.push({
        time: 24,
        formattedTime: "24:00",
        MCP: lastEntry.MCP ?? 0,
        costAtConsumerPheriphery: lastEntry.costAtConsumerPheriphery ?? 0,
        differenceBetweenLandedAndDiscomCost:
          lastEntry.differenceBetweenLandedAndDiscomCost ?? 0,
        totalVariableCost: lastEntry.totalVariableCost ?? 0,
      });
    }

    setGraphData(
      dataToProcess.length > 0 ? dataToProcess : generateFullDayHours()
    );
  };

  const handleInterval = () =>{

  }

  useEffect(() => {
    if (mode === "date") {
      handleSelection(selectedDate.getDate()); // Pass the day number for date mode
    } else if (mode === "week") {
      handleSelection(selectedWeek); // Pass the selectedWeek object for week mode
    } else if (mode === "month") {
      handleSelection(selectedMonth); // Pass the selected month for month mode
    }
  }, [mode, selectedMonth, selectedDate, selectedWeek]);

  const renderFeasibleSavingsLine = () => {
    const segments = [];
    let currentSegment = [];

    for (let i = 0; i < graphData.length; i++) {
      const point = graphData[i];
      if (currentSegment.length === 0) {
        currentSegment.push(point);
      } else {
        const prevPoint = currentSegment[currentSegment.length - 1];
        // If the sign is the same, continue adding the point.
        if (
          Math.sign(point.differenceBetweenLandedAndDiscomCost) ===
          Math.sign(prevPoint.differenceBetweenLandedAndDiscomCost)
        ) {
          currentSegment.push(point);
        } else {
          // Sign changes: include the current point in both segments for a smooth join.
          currentSegment.push(point);
          segments.push(currentSegment);
          currentSegment = [point]; // Start new segment from current point.
        }
      }
    }
    if (currentSegment.length > 0) segments.push(currentSegment);

    return segments.map((segment, idx) => {
      const strokeColor =
        segment[0].differenceBetweenLandedAndDiscomCost <= 0 ? "red" : "green";

      return (
        <Line
          key={`feasible-savings-seg-${idx}`}
          data={segment} // This segment data includes the joining point.
          yAxisId="left"
          type="monotone" // Change this to "monotone" or "basis"
          dataKey="differenceBetweenLandedAndDiscomCost"
          stroke={strokeColor}
          strokeWidth={2}
          fillOpacity={0}
          isAnimationActive={true}
          dot={{ r: 1 }}
          name="Feasible Savings"
        />
      );
    });
  };

  const CustomLegend = (props) => {
    const { payload } = props;
    const [tooltip, setTooltip] = useState(null);

    const descriptions = {
      "Adjusted Discom Variable Charge":
        "Discom energy charge and FAC adjusted for the given ToD incentives / penalties.",
      "Adjusted MCP: landed cost for consumer":
        "IEX GDAM MCP adjusted for PX charges and losses with additional CSS and AS to show the landed cost at the consumer periphery.",
      "Feasible Savings":
        "Savings possible for consumer - Difference between Adjusted DISCOM VC and Adjusted MCP",
      "IEX GDAM MCP (Rs/unit)":
        "MCP is the market clearing price discovered on the IEX GDAM segment for the chosen delivery date.",
    };

    // Remove duplicate "Feasible Savings" entries
    const filteredPayload = [];
    let feasibleSavingsEntry = null;

    payload.forEach((entry) => {
      if (entry.value === "Feasible Savings") {
        if (!feasibleSavingsEntry) {
          feasibleSavingsEntry = { ...entry };
        }
      } else {
        filteredPayload.push(entry);
      }
    });

    if (feasibleSavingsEntry) {
      const hasPositive = graphData.some(
        (item) => item.differenceBetweenLandedAndDiscomCost > 0
      );
      feasibleSavingsEntry.color = hasPositive ? "green" : "red";
      filteredPayload.push(feasibleSavingsEntry);
    }

    return (
      <div
        style={{
          display: "flex",
          gap: "15px",
          alignItems: "center",
          justifyContent: "center",
          color: "gray",
        }}
      >
        {filteredPayload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              position: "relative",
            }}
            onMouseEnter={() => setTooltip(descriptions[entry.value])}
            onMouseLeave={() => setTooltip(null)}
          >
            <div
              style={{
                width: 14,
                height: 14,
                backgroundColor: entry.color,
                marginRight: 5,
              }}
            />
            <span>{entry.value}</span>
            {tooltip &&
              entry.value ===
                Object.keys(descriptions).find(
                  (k) => descriptions[k] === tooltip
                ) && (
                <div
                  style={{
                    position: "absolute",
                    top: "-30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "gray",
                    color: "white",
                    padding: "5px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {tooltip}
                </div>
              )}
          </div>
        ))}
      </div>
    );
  };

  const formatValue = (value) => (value !== undefined ? value.toFixed(2) : "");

  const renderTableRows = () => {
    return graphData.map((entry, index) => (
       index < 96 && <tr key={index} >
       { index %4 === 0 && <td  rowSpan={4} className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">{(index /4 ) + 1 }</td>}
        
        <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">{entry.formattedTime}</td>
        <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.powerConsumptionData)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.discomVariableCost)}
            </td>
            
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.discomTodCharges)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.totalDiscomVariableCharge)}
            </td>
            <td className="px-2  border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.iexGdamPrice)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.ctuLosses)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.ctuCharges)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.stuLosses)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.stuCharges)}
            </td>
            <td className="px-2  border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.discomWheelingLoss)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.discomWheelingCharges)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.crossSubsidySurchargeCSSCost)}
            </td>
            <td className="px-2  border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.additionalSurchargeCSSCost)}
            </td>
            <td className="px-2 border-2 border-gray-500  py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.electricityDuty)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.schedulingOperatingCharges)}
            </td>
            <td className="px-2 border-2 border-gray-500 py-2 min-w-[140px] text-sm text-center">
                {formatValue(entry.landedIexCostAtConsumerPeriphery)}
            </td>
      </tr>
    ));
  };

  return (
    <div className="shadow-lg border border-gray-300 rounded-lg px-4 pb-4 w-full flex">
      <div className="p-6 w-full">
        <div className="grid sm:pl-[5%] grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2">
          <div className="flex items-center ">
            <label className="block text-gray-600  min-w-[140px] font-medium">
              Select Interval
            </label>
            <div className="flex items-center justify-between ">
              <FilterSelect
                label="Interval"
                value={"15 min"}
                onChange={handleInterval}
                options={[{ label: "15 min", value: "15min" }]}
                maxWidth={100}
              />
            </div>
          </div>

          <div className="flex items-center ">
            <label className="block text-gray-600 min-min-w-[140px] font-medium ">
              Select Month
            </label>
            <div className="flex items-center justify-between ">
              <FilterSelect
                label="Month"
                onChange={handleMonthChange}
                value={selectedMonth}
                options={monthData.map((month) => ({
                  label: month,
                  value: month,
                }))}
                maxWidth={80}
              />
            </div>
          </div>

          {/* Dropdown Selector */}
          <div className="flex items-center ">
            <label className="block text-gray-600 min-w-[110px] font-medium mb-1">
              Select Mode
            </label>
            <FilterSelect
              label="Mode"
              onChange={(e) => {
                handleViewModeChange(e);
                setShowPicker(false); // close picker when changing mode
              }}
              options={[
                { label: "Daily", value: "date" },
                { label: "Weekly", value: "week" },
                { label: "Monthly", value: "month" },
              ]}
              value={mode}
            />
          </div>
          <div className="relative">
            {mode !== "month" && (
              <div className="flex items-center gap-4">
                <label className="block text-gray-600 min-w-[140px] font-medium mb-1">
                  Select {mode.charAt(0).toUpperCase() + mode.slice(1)}
                </label>

                <input
                  type="text"
                  className="border p-2 rounded w-full cursor-pointer bg-white"
                  value={getDisplayValue()}
                  readOnly={mode === "month"} // Make the field readonly when mode is "month"
                  disabled={mode === "month"} // Completely disable the input when mode is "month"
                  onClick={() => {
                    if (mode !== "month") {
                      setShowPicker(!showPicker); // Only toggle the picker if the mode isn't "month"
                    }
                  }}
                />
              </div>
            )}

            {showPicker && (
              <div className="absolute left-0 top-full mt-2 w-full z-10 bg-white shadow-lg rounded">
                {mode === "date" && (
                  <Calendar
                    date={selectedDate}
                    onChange={handleDateChange}
                    minDate={(() => {
                      const firstDay = getFirstDayOfMonth(
                        selectedMonth,
                        currentYear
                      ); // First day of the month
                      return firstDay;
                    })()} // First day of the selected month
                    maxDate={(() => {
                      const firstDay = getLastDayOfMonth(
                        selectedMonth,
                        currentYear
                      ); // First day of the month
                      return firstDay;
                    })()}
                  />
                )}

                {mode === "week" && (
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: new Date(selectedWeek?.start) || new Date(),
                        endDate: new Date(selectedWeek?.end) || new Date(),
                        key: "selection",
                      },
                    ]}
                    onChange={handleWeekChange}
                    showMonthAndYearPickers={false}
                    showDateDisplay={false}
                    showPreview={false}
                    rangeColors={["#3b82f6"]}
                  />
                )}
                {mode === "month" && (
                  <Calendar
                    onChange={handleMonthChange}
                    showMonthAndYearPickers
                  />
                )}
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <label className="flex sm:pl-[5%] items-center gap-2">
              <input
                type="checkbox"
                checked={showMCP}
                onChange={() => setShowMCP(!showMCP)}
                className="w-4 h-4"
              />
              <span className="min-w-[140px]">
              Show MCP
              </span>
            </label>
            <CustomTooltop
      size="lg"
      text={`MCP is the market clearing price discounted in the IEX GDAM segment for the related delivery date`}
    />
            {mode === "date" && (
  <>
    <div
      onClick={() => setShowIexTable(!showIexTable)}
      className={`relative inline-block min-w-[48px] w-12 h-6 mx-4 align-middle select-none transition duration-200 ease-in ${
        showIexTable ? "bg-blue-500" : "bg-gray-900"
      } rounded-full`}
      style={{ backgroundColor: showIexTable ? "blue":"gray" }} // Inline styles for debugging
    >
      <span
        className={`absolute block w-6 h-6  rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-200 ease-in-out transform ${
          showIexTable ? "translate-x-full bg-white" : "bg-white"
        }`}
      ></span>
    </div>
    <span className="ml-2 min-w-[140px] text-gray-700">
      {showIexTable ? "Show Chart" : "Show Table"}
    </span>

  </>
)}
          </div>
        </div>
        {/* Graph Display */}
        {showIexTable && mode === 'date'  ? (
                    <div className="p-6 w-full">
                        <div className="overflow-x-auto thin-scrollbar rounded-[15px] upload-bills max-h-[450px]">
                            <table className="w-full border-collapse text-center rounded-[20px] bg-white border border-gray-300">
                                <thead className="bg-green-700 text-white sticky top-[-2px] rounded-[20px]">
  <tr>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Hour</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Time</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Consumption (kWh)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Discom Variable Cost (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Discom TOD Charges (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Total Discom Variable Charge (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">IEX G-DAM price (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">CTU Losses (%)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">CTU Charges (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">STU Losses (%)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">STU Charges (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Discom Wheeling Loss (%)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Discom Wheeling Charges (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Cross Subsidy Charges (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Additional Surcharge (Rs./Unit)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Electricity Duty (%)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Scheduling / Operating Charges (Rs.)</th>
    <th className="px-2 py-2 min-w-[140px] text-sm md:text-md">Landed IEX cost at Consumer Periphery (Rs.)</th>
  </tr>
</thead>
                                <tbody>{renderTableRows()}</tbody>
                            </table>
                        </div>
                    </div>
) : (
  <div className="w-full min-full overflow-x-auto h-[400px]">
    <div className="min-w-[800px] h-full overflow-auto">
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={graphData}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            type="number"
            domain={[0, 24]}
            tickCount={25}
            tickFormatter={(tick) => `${tick}`}
            tick={{ fontSize: 12 }}
            label={{
              value: "Time interval (Hours)",
              position: "insideBottom",
              offset: -5,
            }}
          />
          <YAxis
            yAxisId="left"
            label={{
              value: "Prices (Rs/ kWh)",
              angle: -90,
              offset: 10,
              position: "insideLeft",
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{
              value: "Prices (Rs/ kWh)",
              angle: -90,
              position: "insideRight",
              offset: 10,
              dy: -120,
            }}
          />
          <Tooltip
            content={({ payload, label }) => {
              if (!payload || payload.length === 0) return null;
              const dataEntry = graphData.find(
                (item) => item.time === label
              );
              const feasibleSavings =
                dataEntry?.differenceBetweenLandedAndDiscomCost;

              const filteredPayload = payload.filter(
                (lineEntry) => lineEntry.name !== "Feasible Savings"
              );

              if (feasibleSavings !== undefined) {
                filteredPayload.push({
                  name: "Feasible Savings",
                  value: feasibleSavings,
                  color: feasibleSavings > 0 ? "green" : "red",
                });
              }

              return (
                <div className="bg-white p-2 border border-gray-300 shadow-md rounded">
                  <p className="font-bold">
                    {dataEntry?.formattedTime || "Unknown Time"}
                  </p>
                  {filteredPayload.map((lineEntry, index) => (
                    <p key={index} style={{ color: lineEntry.color }}>
                      {lineEntry.name}: {lineEntry.value.toFixed(2)} Rs/unit
                    </p>
                  ))}
                </div>
              );
            }}
          />
          <Legend
            wrapperStyle={{ margin: "-1rem" }}
            content={<CustomLegend />}
          />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="totalVariableCost"
            fillOpacity={0}
            strokeWidth={2}
            dot={{ r: 1 }}
            isAnimationActive={true}
            name="Adjusted Discom Variable Charge"
          />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="costAtConsumerPheriphery"
            isAnimationActive={true}
            stroke="#FFD700"
            fillOpacity={0}
            strokeWidth={2}
            dot={{ r: 1 }}
            name="Adjusted MCP: landed cost for consumer"
          />
          {renderFeasibleSavingsLine()}
          {showMCP && (
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="MCP"
              stroke="black"
              strokeWidth={2}
              isAnimationActive={true}
              dot={{ r: 1, fill: "black" }}
              connectNulls={true}
              name="IEX GDAM MCP (Rs/unit)"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  </div>
)}
      </div>
    </div>
  );
};

export default McpGraphChart;
