import { faCircleInfo, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

const iconMap = {
  faCircleInfo,
  faWarning,
};

const CustomTooltop = ({
  text,
  size,
  disable,
  iconColor = "text-gray-500",
  iconName = "faCircleInfo",
}) => {
  const [tooltipId, setTooltipId] = useState("");

  useEffect(() => {
    setTooltipId(`my-tooltip-${Math.random().toString(36).substr(2, 9)}`);
  }, []);

  return (
    <div className={disable ? "hidden" : ""}>
      <button data-tooltip-id={tooltipId}>
        <FontAwesomeIcon
          size={size || "sm"}
          className={iconColor} // Use Tailwind class for color
          icon={iconMap[iconName]}
        />
      </button>
      <Tooltip
        style={{ backgroundColor: "#90EE90", color: "#222", zIndex: "41" }}
        id={tooltipId}
      >
        <div
          className="z-50"
          style={{
            display: "flex",
            flexDirection: "column",
            whiteSpace: "normal",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </Tooltip>
    </div>
  );
};

export default CustomTooltop;
