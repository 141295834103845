import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode"; // Add this import to decode the token

const CsvFieldSelector = ({
    headers,
    handleDownload,
    selectedHeaders,
    setSelectedHeaders,
    py,
    reportDiscomCodes,
    setReportDiscomCodes,
    activeTab, setActiveTab,
    selectedDiscoms, setSelectedDiscoms
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    // Fetch discom codes using axios
    useEffect(() => {
        const fetchDiscomCodes = async () => {
            try {
                const data = localStorage.getItem("user"); // Retrieve the token from local storage
                const token = JSON.parse(data)?.result?.token;
                
                if (!token) {
                    console.error("Token not found in local storage.");
                    return;
                }

                const decodedToken = jwtDecode(token); // Decode the token
                const orgId = decodedToken.organisationId; // Extract organisationId from the token payload

                if (!orgId) {
                    console.error("Organisation ID not found in token payload.");
                    return;
                }

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/unit/list`,
                    {
                        organisationId: orgId,
                    },
                    {
                        headers: {
                            Accept: "application/json, text/plain, */*",
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.data.statusCode === 200) {
                    const codes = response.data.data.results.map(
                        (result) => result.discomCode
                    );
                    const uniqueCodes = [...new Set(codes)];
                    setReportDiscomCodes(uniqueCodes);
                    setSelectedDiscoms(uniqueCodes);
                } else {
                    console.error(
                        "Failed to fetch discom codes:",
                        response.data.message
                    );
                }
            } catch (error) {
                console.error("Error fetching discom codes:", error);
            }
        };

        fetchDiscomCodes();
    }, []);

    // Toggle the popup
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    // Handle checkbox selection for headers
    const handleCheckboxChange = (header) => {
        if (selectedHeaders.includes(header)) {
            setSelectedHeaders(selectedHeaders.filter((h) => h !== header));
        } else {
            setSelectedHeaders([...selectedHeaders, header]);
        }
    };

    // Handle checkbox selection for DISCOMs
    const handleDiscomCheckboxChange = (discom) => {
        if (selectedDiscoms.includes(discom)) {
            setSelectedDiscoms(selectedDiscoms.filter((d) => d !== discom));
        } else {
            setSelectedDiscoms([...selectedDiscoms, discom]);
        }
    };

    // Handle the Select All checkbox
    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedHeaders([]); // Unselect all headers
        } else {
            setSelectedHeaders(headers);
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        setSelectAll(selectedHeaders.length === headers.length);
    }, [selectedHeaders, headers]);

    const handleOnclick = () => {
        if (activeTab === "normal" && selectedHeaders.length === 0) {
            toast.dismiss();
            toast.error("Please select at least one Field.", {
                position: "top-right",
            });
            return;
        } else if (activeTab === "tod" && selectedDiscoms.length === 0) {
            toast.dismiss();
            toast.error("Please select at least one DISCOM.", {
                position: "top-right",
            });
            return;
        }

        if (activeTab === "normal") {
            handleDownload();
        } else if (activeTab === "tod") {
            handleDownload(); 
        }
        toggleModal();
    };

    return (
        <div>
            <div
                onClick={toggleModal}
                className={`flex border border-gray-300 text-white shadow-lg bg-[#067C4E] text-[12px] px-2 ${
                    py ?? "py-1"
                } rounded-md justify-center cursor-pointer items-center gap-2`}
            >
                <FontAwesomeIcon size="2xl" icon={faCloudDownload} />
                Extract Report
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex z-50 items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="m-2">
                        <div className="bg-white rounded-lg w-full sm:w-[1000px] p-5">
                            <div className="flex justify-between items-start">
                                <h2 className="text-xl font-bold mb-4">
                                    Extract Reports
                                </h2>
                                <button
                                    onClick={toggleModal}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    ✖️
                                </button>
                            </div>

                            {/* Tabs */}
                            <div className="flex border-b mb-4">
                                <button
                                    onClick={() => setActiveTab("normal")}
                                    className={`px-4 py-2 ${
                                        activeTab === "normal"
                                            ? "border-b-2 border-green-700 text-green-700"
                                            : "text-gray-500"
                                    }`}
                                >
                                    General  Report
                                </button>
                                <button
                                    onClick={() => setActiveTab("tod")}
                                    className={`px-4 py-2 ${
                                        activeTab === "tod"
                                            ? "border-b-2 border-green-700 text-green-700"
                                            : "text-gray-500"
                                    }`}
                                >
                                    TOD Report
                                </button>
                            </div>

                            {/* Tab Content */}
                            {activeTab === "normal" && (
                                <div>
                                    <div className="space-y-1 grid gap-1 grid-cols-5 py-4">
                                        <div className="flex items-center col-span-5">
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={handleSelectAllChange}
                                                className="form-checkbox h-5 w-5 text-blue-600"
                                            />
                                            <label className="ml-2 text-sm text-gray-700">
                                                Select All
                                            </label>
                                        </div>
                                        {headers
                                            .filter(
                                                (header) => header !== "TOD"
                                            ) // Exclude "TOD" column from normal tab
                                            .map((header, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center py-2"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedHeaders.includes(
                                                            header
                                                        )}
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                header
                                                            )
                                                        }
                                                        className="form-checkbox min-h-5 min-w-5 text-blue-600"
                                                    />
                                                    <label className="ml-2 text-xs text-gray-700">
                                                        {header}
                                                    </label>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            onClick={handleOnclick}
                                            className="mt-4 px-4 h-[40px] bg-green-700 text-white rounded hover:bg-green-900"
                                        >
                                            <FontAwesomeIcon
                                                size="xl"
                                                icon={faCloudDownload}
                                            />{" "}
                                            &ensp; Download Report
                                        </button>
                                    </div>
                                </div>
                            )}

                            {activeTab === "tod" && (
                                <div className="flex flex-col items-center justify-center py-4">
                                <div className="w-full">
                                    <p className="text-sm text-left text-gray-700 mb-1 mt-2">
                                        Select the DISCOMs and Fields below to download the TOD Report.
                                    </p>
                                    <div className="space-y-1 grid gap-1 grid-cols-4 py-1">
                                        {/* DISCOM Selection */}
                                        {reportDiscomCodes.map(
                                            (discom, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center py-2"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedDiscoms.includes(
                                                            discom
                                                        )}
                                                        onChange={() =>
                                                            handleDiscomCheckboxChange(
                                                                discom
                                                            )
                                                        }
                                                        className="form-checkbox min-h-5 min-w-5 text-blue-600"
                                                    />
                                                    <label className="ml-2 text-xs text-gray-700">
                                                        {discom}
                                                    </label>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    </div>
                                    <hr className="w-full bg-gray-900 my-4" />
                                
                                    <div className="space-y-1 grid gap-1 grid-cols-5">
                                        <div className="flex items-center col-span-5">
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={handleSelectAllChange}
                                                className="form-checkbox h-5 w-5 text-blue-600"
                                            />
                                            <label className="ml-2 text-sm text-gray-700">
                                                Select All Fields
                                            </label>
                                        </div>
                                        {headers
                                            .filter((header) => header !== "TOD") // Exclude "TOD" column
                                            .map((header, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center py-2"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedHeaders.includes(
                                                            header
                                                        )}
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                header
                                                            )
                                                        }
                                                        className="form-checkbox min-h-5 min-w-5 text-blue-600"
                                                    />
                                                    <label className="ml-2 text-xs text-gray-700">
                                                        {header}
                                                    </label>
                                                </div>
                                            ))}
                                    </div>
                                
                                    <button
                                        onClick={handleOnclick}
                                        className="mt-4 px-4 h-[40px] bg-green-700 text-white rounded hover:bg-green-900"
                                    >
                                        <FontAwesomeIcon
                                            size="xl"
                                            icon={faCloudDownload}
                                        />{" "}
                                        &ensp; Download TOD Report
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CsvFieldSelector;
