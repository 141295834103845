import React, { useState } from 'react';

const FilterSelect = ({ label, options, value, onChange,height,maxWidth }) => {
  return (
    <select 
    
    style={{
        height:height?height :"40px",
        maxWidth:maxWidth?maxWidth:"100%"
    }}
     value={value} onChange={onChange}  className={`${value ? "text-black":"text-gray-400"} w-[100%] px-2 h-[30px] border border-gray-200 rounded-[10px] shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white`}>
      {label &&
        <option key={""} value={""} disabled>
          {"Select "}{label}
        </option>
      }
      {options?.map((option, index) => (
        <option className='text-black' key={index} value={option.value}>{option.label}</option>
      ))}
    </select>
  );
};

export default FilterSelect;
