export class ConstantUtils {
  static getMonths() {
    return {
      JAN: "january",
      FEB: "february",
      MAR: "march",
      APR: "april",
      MAY: "may",
      JUN: "june",
      JUL: "july",
      AUG: "august",
      SEP: "september",
      OCT: "october",
      NOV: "november",
      DEC: "december",
    };
  }

  getMonthsFromApril(year) {
    return {
      APR: "apr",
      MAY: "may",
      JUN: "jun",
      JUL: "jul",
      AUG: "aug",
      SEP: "sep",
      OCT: "oct",
      NOV: "nov",
      DEC: "dec",
      JAN: "jan",
      FEB: "feb",
      MAR: "mar",
    };
  }




  getMonthsFromAprilFinancial(year) {
    return [
      `APR-${year}`,
      `MAY-${year}`,
      `JUN-${year}`,
      `JUL-${year}`,
      `AUG-${year}`,
      `SEP-${year}`,
      `OCT-${year}`,
      `NOV-${year}`,
      `DEC-${year}`,
      `JAN-${parseInt(year)  + 1}`,
      `FEB-${parseInt(year)  + 1}`,
      `MAR-${parseInt(year)  + 1}`,
    ];
     
  }
  getAllStaticMonths() {
    return ConstantUtils.getMonths();
  }
  getAllStaticMonthsFromApril() {
    return this.getMonthsFromApril();
  }
  getLineGraphColors() {
    return [
      "#355e3b", // Dark Green
      "#1cac78",
      "#90ee90",
      "gray"
    ];
  }

  getGraphColors() {
    return {
      peakDemand: "#0a7e8c",
      contractDemand: "#006400",
      percentContractDemand: "#3cb371",
      LF: "#9dc209",
      demand: "#009b7d",
      B2: "#87bc45",
      R: "#ef9b20",
      G2: "#edbf33",
      Y: "#ede15b",
      G1: "#edbf33",
      B1: "#87bc45",
      ENERGY:"#"
    };
  }

  getGraphToolTipData() {
    return {
      annualEnergy: { disableToolTip: false, text: "Annual Comparison Of Energy Consumption From Your Discom" },
      annualCost: { disableToolTip: false, text: "Annual Comparison Of Billing By Your Discom" },
      MonthlyEnergy: { disableToolTip: false, text: "Annual ToD Slot Wise Energy Consumption" },
      MonthlyCost: { disableToolTip: false, text: "Annual ToD Slot Wise Billing" },
      MonthlyTodEnergy: {
        disableToolTip: false,
        text: "MonthLy Tod SlotWise Energy Consumption",
      },
      MonthlyTodCost: { disableToolTip: false, text: "MonthLy ToD SlotWise Total Bill" },
      MonthlyBillDistribution: { disableToolTip: false, text: "Distribution Of Bill Charges across Various Components" },
      annualSolarEnergy: { disableToolTip: false, text: "Annual Comparison Of Energy Consumption From Your Solar Source" },
      annualSolarCost: { disableToolTip: false, text: " Annual Comparison Of Estimated Cost Of Your Solar Energy" },
      annualTotalEnergy: { disableToolTip: false, text: "Annual Comparison Of Total Energy Consumption Combining Discom And Solar Sources" },
      annualTotalCost: { disableToolTip: false, text: "Annual Comparison Of Total Estimated Cost Of Your Discom And Solar Sources." },
      customised: { disableToolTip: false, text: "Daily Estimated SlotWise Distribution Of Energy Comsumption Based On Your Customised Input" },
      futureEnergy: { disableToolTip: false, text: "Monthly Projection Of ToD Slot Wise Energy Consumption Based On Your Future Load Inputs" },
      futureCost: { disableToolTip: false, text: "Monthly Projection Of ToD Slot Wise Billing Based On Your Future Load Inputs" },
      comparisonView: { disableToolTip: false, text: "info Drilled Down and Comparison Of Energy Consumption By Various Consumer Numbers Of Your Organisation." },
      comparisonViewEnergy: { disableToolTip: false, text: "Annual View of ToD Wise Energy Consumption By Individual Consumer Number In Your Organisation." },
      comparisonViewCost: { disableToolTip: false, text: "Annual View of ToD Wise Billing  By Individual Consumer Number In Your Organisation." },
      DashboardView: { disableToolTip: false, text: "Dashboard view" },
    //   DashboardUnitConsumed: { disableToolTip: false, text: "Dashboard view" },
    //   DashboardTotalBillAmount: { disableToolTip: false, text: "Dashboard view" },
    //   DashboardTodCharges: { disableToolTip: false, text: "Dashboard view" },
    //   DashboardAvgMonthlyBill: { disableToolTip: false, text: "Dashboard view" },
    //   DashboardTodAvgMonthlyConsumption: { disableToolTip: false, text: "Dashboard view" },
    //   DashboardEmmission: { disableToolTip: false, text: "Dashboard view" },
    //   DashboardLoadFactor: { disableToolTip: false, text: "Dashboard view" },
    //   DashboardSaving: { disableToolTip: false, text: "Dashboard view" },
    };
  }

  getDashboardToolTipData() {
    return {
      DashboardView: { disableToolTip: false, text: "Dashboard view" },
      DashboardUnitConsumed: { disableToolTip: false, text: "Total units consumed during the selected period for the selected consumer numbers" },
      DashboardTotalBillAmount: { disableToolTip: false, text: " Total billing amount for the selected period for the selected consumer numbers" },
      DashboardTodCharges: { disableToolTip: false, text: "Tod Charges for the selected period for the selected consumer numbers" },
      DashboardAvgMonthlyBill: { disableToolTip: false, text: "Average Monthly Bill for the selected period for the selected consumer numbers" },
      DashboardTodAvgMonthlyConsumption: { disableToolTip: false, text: "Average Monthly units consumed for the selected period for the selected consumer numbers" },
      DashboardEmmission: { disableToolTip: false, text: "Total Emissons for the selected period for the selected consumer numbers" },
      DashboardLoadFactor: { disableToolTip: false, text: "Number of Bills less than the selected Load Factor for the selected period for the selected consumer numbers" },
      DashboardSaving: { disableToolTip: false, text: "Total possible savings with Ampera recommendations for the selected period for the selected consumer numbers" },
      DashboardArrears: { disableToolTip: false, text: "Total possible Arrears with Ampera recommendations for the selected period for the selected consumer numbers" },
      DashboardPanalty: { disableToolTip: false, text: "Total possible Penalty with Ampera recommendations for the selected period for the selected consumer numbers" },    
      DashboardIncentive: { disableToolTip: false, text: "Total possible Incentive with Ampera recommendations for the selected period for the selected consumer numbers" },
      DashboardGreenPowerTariffUnits: { 
        disableToolTip: false, 
        text: "Total possible Green Power Tariff Units with Ampera recommendations for the selected period for the selected consumer numbers" 
      },
      DashboardGreenPowerTariffCost: { 
        disableToolTip: false, 
        text: "Total possible Green Power Tariff Cost with Ampera recommendations for the selected period for the selected consumer numbers" 
      },
    };
  }

  getStatusToolTipData = () => {
    return {
      Status: {
        disableToolTip: false,
        text: `
          <div>
            <strong>COMPLETED:</strong> The data is succesfully extracted from bills.<br/><br/>
            <strong>ADDED:</strong> The extracted data is succesfully added to the system.<br/><br/>
            <strong>MODIFIED:</strong> The data has been modified after its initial addition.<br/><br/>
            <strong>VALIDATED:</strong> The data has been validated and confirmed as correct.
          </div>
        `
      }
    };
  };



  getLastFiveFinancialYears(count) {
    let yearCount = 2;
    if(count){
         yearCount = count;
    }
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are zero-based
    const years = [];
  
    // Determine the financial year based on the current month
    let startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
  
    for (let i = 0; i < yearCount; i++) {
      const endYear = (startYear + 1) % 100;
      years.push(`FY${startYear}-${endYear < 10 ? '0' + endYear : endYear}`);
      startYear -= 1;
    }

    return years;
  }
  getNextFinancialYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
  
    // Determine the start year of the next financial year
    let startYear = currentMonth >= 4 ? currentYear + 1 : currentYear;
    let endYear = startYear + 1;
  
    return `FY${startYear}-${endYear % 100 < 10 ? '0' + (endYear % 100) : endYear % 100}`;
  }

  getRecommedationStateCodes() {
    const states = [{label: 'Maharashtra',stateCode:'IN-MH'}];
    return states;
  }
}
