import React, { useState, useEffect } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faX } from "@fortawesome/free-solid-svg-icons";
import { getUserOrgId } from "../../utils/authenticationUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import CustomTooltop from "../../components/tooltips/CustomTooltop";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import MonthlyFloatingBarChart from "./MonthlyFloatingBarChart";
import McpGraphChart from "./McpGraphChart";

const PxAnalytics = ({
  setShowPxAnalyticsChart,
  startDate,
  endDate,
  meterId,
  unitName,
  selectedRecommedationYear,
}) => {
  const { id } = useParams();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const navigate = useNavigate();
  const [orgID, setOrgID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pxData, setPxData] = useState(null);
  const [savingsData, setSavingsData] = useState([]);
  const [monthData, setMonthData] = useState([]);

  const getOrganisationId = async () => {
    const orgId = await getUserOrgId();
    setOrgID(orgId);
  };

  useEffect(() => {
    getOrganisationId();
  }, []);

  useEffect(() => {
    const fetchPowerConsumptionData = async () => {
      try {
        setLoading(true);
        const payload = {
          startDate: startDate,
          endDate: endDate,
          organisationId: orgID,
          stateCode: "IN-MH",
          meterId: meterId,
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/recommendation/px-rec?pricetype=constantprice`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json(); // Parse response properly
        setPxData(data.data[0]);
        setTimeout(() => {
          setLoading(false);
        }, 10);
      } catch (error) {
        console.error("Error fetching power consumption data:", error);
        setLoading(false);
      }
    };

    if (!orgID) return;

    fetchPowerConsumptionData();
  }, [orgID]);
  //   }, []); //

  useEffect(() => {
    if (!pxData?.calculations_steps?.savingsFromExchangeTransactions) return;

    const transactions =
      pxData.calculations_steps.savingsFromExchangeTransactions;

    // Transform data: rows should be keys like "totalHours", and columns should be months
    const formattedData = {
      totalHours: {},
      totalCharge: {},
      totalPxChargeMonth: {},
      maxWindow: {},
      dailyData: {},
      formattedMonth: {},
    };

    let months = [];
    Object.entries(transactions).forEach(([month, details], index) => {
      const [startMonth, startYear] = startDate.split("/"); // Split the date string to extract month and year
      let currentYear = parseInt(startYear, 10); // Convert year to number

      if (
        index > 0 &&
        new Date(`${month}-01`).getMonth() <
          new Date(`${Object.keys(transactions)[index - 1]}-01`).getMonth()
      ) {
        currentYear++;
      }
      const formattedMonth = `${month}-${currentYear}`;
      months.push(month);
      formattedData.totalHours[month] = details.totalHours || 0;
      formattedData.totalCharge[month] = details.totalCharge || 0;
      formattedData.totalPxChargeMonth[month] = details.totalPxChargeMonth || 0;
      formattedData.maxWindow[month] = details.maxWindow || 0;
      formattedData.formattedMonth[month] = formattedMonth;

      // Ensure dailyData[month] is initialized as an object
      if (!formattedData.dailyData[month]) {
        formattedData.dailyData[month] = {};
      }

      formattedData.dailyData[month]["data"] = details.data || {};
    });
    setMonthData(months);
    setSavingsData(formattedData);
  }, [pxData]);

  useEffect(() => {}, []);

  function formatAsINR(amount) {
    if (amount === null) {
      return 0;
    }
    const numericAmount =
      typeof amount === "string" ? parseFloat(amount) : amount;

    if (isNaN(numericAmount)) {
      return 0;
      // throw new Error("Invalid amount");
    }

    return numericAmount.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
  }

  function convertDateFormat(dateString) {
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const [month, year] = dateString.split("-");
    const monthNumber = monthMap[month];

    if (!monthNumber || !year) {
      throw new Error("Invalid date format. Expected format: Jan-2023");
    }

    return `${monthNumber}/${year}`;
  }

  const handleView = (conNo, monthandYear) => {
    const updatedMonthAndYear = convertDateFormat(monthandYear);
    const [month, year] = updatedMonthAndYear.split("/");
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    let adjustedYear = parseInt(year);

    if (
      parseInt(year) > currentYear ||
      (parseInt(year) === currentYear && parseInt(month) > currentMonth)
    ) {
      adjustedYear = adjustedYear - 1;
    }

    const adjustedMonthAndYear = `${month}/${adjustedYear}`;
    window.open(
      `/compare-ocr-bills?conNo=${conNo}&startDate=${adjustedMonthAndYear}&endDate=${adjustedMonthAndYear}&redirect=recommendation`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="max-w-[1920px]  flex">
      {/* <div className="hidden sm:block">
        <Sidebar />
      </div> */}

      <div className={` w-[100%] `}>
        {pxData && !loading ? (
          <div className=" ">
            <div className="flex  justify-between items-center">
              <h2 className="text-2xl font-medium px-4">PX Power Exchange</h2>
              <div className="flex  justify-end gap-4  m-2 ">
                <div
                  data-tooltip-id="my-tooltip"
                  onClick={() => navigate("/")}
                  className="cursor-pointer bg-green-700 rounded-lg text-white px-4 py-2"
                >
                  <FontAwesomeIcon data-tip="Click to download" icon={faHome} />
                </div>
                <div
                  onClick={() => setShowPxAnalyticsChart(false)}
                  className="cursor-pointer bg-green-700 rounded-lg text-white px-4 py-2"
                >
                  <FontAwesomeIcon data-tip="close PopUp" icon={faX} />
                </div>
                <ReactTooltip
                  style={{
                    backgroundColor: "#90EE90",
                    color: "#222",
                    zIndex: "41",
                  }}
                  place={"bottom"}
                  id="my-tooltip"
                >
                  <div>
                    <h3>Go To Dashboard</h3>
                  </div>
                </ReactTooltip>
              </div>
            </div>

            <div className="px-5 flex flex-col sm:flex-row  sm:space-y-0 gap-[20px] space-y-2 font-medium text-sm">
              <span>
                <span className="font-medium text-sm">Year:</span>{" "}
                {selectedRecommedationYear}
              </span>
              <span>
                <span className="font-medium text-sm"> Consumer No: </span>{" "}
                {pxData.consumerNo}
              </span>
              <span>
                <span className="font-medium text-sm">Unit Name:</span>{" "}
                {unitName}
              </span>
            </div>

            <div className="p-5 space-y-5 max-h-[90vh] pb-[20vh] sm:pb-[5vh] overflow-auto  upload-bills   ">
              {/* First Table */}
              <div>
                <h2 className="text-md md:text-xl p-2 bg-gray-100 flex justify-between font-medium w-full">
                  Saving From PX
                </h2>
                <div className="overflow-auto border border-gray-300  rounded-[10px]">
                  <table className="w-full border-collapse  p-1 text-center  ">
                    <thead>
                      <tr className="bg-green-700 text-white">
                        <th className="px-2 cursor-pointer py-2 w-[8.3%] text-sm md:text-md">
                          Months
                        </th>
                        {Object.keys(savingsData.totalCharge || {}).map(
                          (month) => (
                            <th
                              key={month}
                              className="px-2 cursor-pointer py-2 w-[8.3%] text-sm md:text-md"
                              onClick={() =>
                                handleView(
                                  pxData.consumerNo,
                                  savingsData.formattedMonth[month]
                                )
                              }
                            >
                              <span className="flex gap-3 justify-center">
                                {savingsData.formattedMonth[month]}
                                <CustomTooltop
                                  size="lg"
                                  text={`Click to view bill for ${month}`}
                                  color={"white"}
                                ></CustomTooltop>
                              </span>
                            </th>
                          )
                        )}
                        <th className="px-2 cursor-pointer py-2 w-[8.3%] text-sm md:text-md bg-green-700">
                          Total
                        </th>{" "}
                        {/* Total Column */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="px-2 py-2 w-[8.3%] text-sm">
                          Total savings (Rs)
                        </td>
                        {Object.keys(savingsData.totalCharge || {}).map(
                          (month) => (
                            <td
                              key={month}
                              className="px-2 py-2 w-[8.3%] text-sm"
                            >
                              {formatAsINR(
                                Math.round(savingsData.totalCharge[month]) || 0
                              )}
                            </td>
                          )
                        )}
                        <td className="px-2 py-2 w-[8.3%] text-sm">
                          {formatAsINR(
                            Object.values(savingsData.totalCharge || {}).reduce(
                              (sum, value) => sum + (Math.round(value) || 0),
                              0
                            )
                          )}
                        </td>{" "}
                        {/* Calculated Total */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Second Table */}
              <div className=" rounded-[10px]">
                <h2 className="text-md md:text-xl p-2 bg-gray-100 flex justify-between font-medium w-full">
                  Total No of Hours
                </h2>
                <div className="overflow-auto  border border-gray-300  rounded-[10px]">
                  <table className="w-full border-collapse  text-center   rounded-[20px]">
                    <thead className="bg-green-700 text-white">
                      <tr className=" ">
                        <th className="px-2  cursor-pointer py-2 w-[8.3%] text-sm md:text-md">
                          Months
                        </th>
                        {Object.keys(savingsData.totalHours || {}).map(
                          (month) => (
                            <th
                              key={month}
                              className="px-2 cursor-pointer py-2 w-[8.3%] text-sm md:text-md"
                            >
                              {savingsData.formattedMonth[month]}
                            </th>
                          )
                        )}
                        <th className="px-2 cursor-pointer py-2 w-[8.3%] text-sm md:text-md">
                          Total
                        </th>{" "}
                        {/* Total Column */}
                      </tr>
                    </thead>
                    <tbody className=" ">
                      <tr>
                        <td className="px-2 py-2 w-[8.3%] text-sm">
                          Total Hours
                        </td>
                        {Object.keys(savingsData.totalHours || {}).map(
                          (month) => (
                            <td
                              key={month}
                              className="px-2 py-2 w-[8.3%] text-sm"
                            >
                              {savingsData.totalHours[month] || 0} hrs
                            </td>
                          )
                        )}
                        <td className="px-2 py-2 w-[8.3%] text-sm">
                          {Object.values(savingsData.totalHours || {}).reduce(
                            (sum, value) => sum + (value || 0),
                            0
                          )}{" "}
                          hrs
                        </td>{" "}
                        {/* Calculated Total */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h2 className="text-md md:text-xl p-2 bg-gray-100 flex justify-between font-medium w-full">
                  Electricity Market Analysis – Price Comparison
                </h2>
                <div className={`flex items-center justify-between`}>
                  <McpGraphChart
                    monthData={monthData}
                    savingsData={savingsData.dailyData}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              </div>

              <div className={`flex items-center flex-col justify-between`}>
                <h2 className="text-md md:text-xl p-2 bg-gray-100 flex justify-between font-medium w-full">
                  Max trading window - Monthly Analysis
                </h2>
                <div
                  className={`flex items-center flex-col w-full justify-between`}
                >
                  <MonthlyFloatingBarChart
                    monthData={monthData}
                    savingsData={savingsData.dailyData}
                  />
                </div>
              </div>

              <div>
                <h2 className="text-md md:text-xl p-2 bg-gray-100 flex justify-between font-medium w-full">
                  Time Window with Maximum Occurrence
                </h2>
                <div className="overflow-auto border border-gray-300 rounded-[10px]">
                  <table className="w-full border-collapse p-1 text-center">
                    <thead>
                      <tr className="bg-green-700 text-white">
                        <th className="px-2 cursor-pointer py-2 w-[8.3%] text-sm md:text-md">
                          Months
                        </th>
                        {Object.keys(savingsData.totalCharge || {}).map(
                          (month) => (
                            <th
                              key={month}
                              className="px-2 cursor-pointer py-2 w-[8.3%] text-sm md:text-md"
                            >
                              <span className="flex gap-3 justify-center">
                                {savingsData.formattedMonth[month]}
                              </span>
                            </th>
                          )
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {/* Row for Most Frequent Timeslot */}
                      <tr>
                        <td className="px-2 py-2 w-[8.3%] text-sm">
                          Most Frequent Timeslot
                        </td>
                        {Object.keys(savingsData.dailyData || {}).map(
                          (month) => {
                            const dailyData =
                              savingsData.dailyData[month]?.data || [];

                            // Extract all maxslot entries from dailyData
                            const allSlots = dailyData.flatMap(
                              (entry) => entry.maxslot || []
                            );

                            // Calculate frequency of timeslots using start and end
                            const timeslotFrequency = allSlots.reduce(
                              (acc, { start, end }) => {
                                const key = `${start} - ${end}`;
                                acc[key] = (acc[key] || 0) + 1;
                                return acc;
                              },
                              {}
                            );

                            // Get the most frequent timeslot and its count
                            const mostFrequent = Object.entries(
                              timeslotFrequency
                            ).reduce(
                              (max, [timeslot, count]) =>
                                count > max.count ? { timeslot, count } : max,
                              { timeslot: "N/A", count: 0 }
                            );

                            return (
                              <td
                                key={month}
                                className="px-2 py-2 w-[8.3%] text-sm"
                              >
                                {mostFrequent.timeslot} ({mostFrequent.count})
                              </td>
                            );
                          }
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="min-h-[100vh] flex justify-center items-center">
            <LoaderComponent />
          </div>
        )}
      </div>
    </div>
  );
};

export default PxAnalytics;
