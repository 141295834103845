import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBoxV2 from "../../components/inputbox/InputboxV2";
import Abutton from "../../components/button/Abutton";
import ASelectv1 from "../../components/select/ASelectV1";
import InputBoxV1 from "../../components/inputbox/InputBoxV1";
import { getData, postData, updateData } from "../../api/apiDataExchange";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import ASelectv2 from "../../components/select/ASelectV2";
import TimeDurationPicker from "../../components/timePicker/TimeDurationPicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddHoliday from "../../components/addHoliday/AddHoliday";
import ShiftSlotSelector from "../../components/shiftSlotSelector/ShiftSlotSelector";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark, faForward } from "@fortawesome/free-solid-svg-icons";

const UpdateUnitSetting = () => {
  const [name, setName] = useState("");
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [excelTimeBlock, setExcelTimeBlock] = useState("");
  const [holidayList, setHolidayList] = useState([{ date: "", note: "" }]);
  const [addHolidayError, setAddHolidayError] = useState("");
  const [timePickerError, setTimePickerError] = useState("");
  const [timeSlots, setTimeSlots] = useState([{ startTime: "", endTime: "" }]);
  const [weekdayShiftSlots, setWeekdayShiftSlots] = useState([{ startTime: '', endTime: '' }]);
  const [weekendShiftSlots, setWeekendShiftSlots] = useState([{ startTime: '', endTime: '' }]);
  const [dateTimeFields, setDateTimeFields] = useState([
    { date: "", startTime: "", endTime: "" },
  ]);
  const [errorMaintainance, setErrorMaintainance] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [errors, setErrors] = useState([]);
  const [timeDurationError, setTimeDurationError] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [eExcelTimeBlock, setEExcelTimeBlock] = useState("");
  const [orgName, setOrgName] = useState("");
  const [factoryName, setFactoryName] = useState("");
  const [numberOfWeekdayShifts, setNumberOfWeekdayShifts] = useState(1);
  const [numberOfWeekEndShifts, setNumberOfWeekEndShifts] = useState(0);
  const [numberOfShiftsOnSunday, setNumberOfShiftsOnSunday] = useState(0);
  const [numberOfShiftsOnSaturday, setNumberOfShiftsOnSaturday] = useState(0);
  const [isSameShift, setIsSameShift] = useState(true);
  const [sundayShiftsSlots, setSundayShiftsSlots] = useState([]);
  const [saturdayShiftsSlots, setSaturdayShiftsSlots] = useState([]);
  const [nonProductiveDayDuration, setNonProductiveDayDuration] = useState({
    startDate: null,
    endDate: null
  });

  const [firstDayOfMonth, setFirstDayOfMonth] = useState();
  const [esTarget, setEsTarget] = useState();
  const [esTargetReference, setEsTargetReference] = useState("");
  const [exporterToEurope, setExporterToEurope] = useState();
  const [isCBAMApplicable, setIsCBAMApplicable] = useState();
  const [isBRSRMandatory, setIsBRSRMandatory] = useState();

  const [planForLoadEnhancement, setPlanForLoadEnhancement] = useState();
  const [loadModificationExisting, setLoadModificationExisting] = useState();
  const [loadAdditionNewPremises, setLoadAdditionNewPremises] = useState();
  const [loadType, setLoadType] = useState();
  const [capacityOfLoad, setCapacityOfLoad] = useState();
  const [operatingHours, setOperatingHours] = useState({
    weekDays: [],
    weekEnd: []
  });
  const [currentStep, setCurrentStep] = useState(0);

  // Function to handle date range change
  const handleDateRangeChange = (dates) => {
    const [startDate, endDate] = dates;
    setNonProductiveDayDuration({ startDate, endDate });
  };

  useEffect(() => {
    const fetchData = async () => {
      const unitResponse = await getData(
        `${process.env.REACT_APP_API_URL}/unit/${id}`
      );
      // console.log(unitResponse.result.settings);
      if (unitResponse.result) {
        setOrgName(capitalizeSentence(unitResponse.result.organisationId.name));
        setFactoryName(unitResponse.result.factoryId.name);
        setName(unitResponse.result.name);
      }
      if (unitResponse.result.settings) {
        setExcelTimeBlock(unitResponse.result.settings?.excelTimeSlot);
        if (unitResponse.result.settings?.weekdayShiftSlots) {
          const weekDaySlots = unitResponse.result.settings.weekdayShiftSlots?.map(slot => {
            const [startTime, endTime] = slot.split("-");
            return { startTime, endTime };
          });
          setWeekdayShiftSlots(weekDaySlots);
        }
        setNumberOfWeekdayShifts(unitResponse.result.settings?.numberOfWeekdayShifts);
        setNumberOfWeekEndShifts(unitResponse.result.settings?.numberOfShiftsOnSaturday);
        setNumberOfShiftsOnSunday(unitResponse.result.settings?.numberOfShiftsOnSunday);
        setNumberOfShiftsOnSaturday(unitResponse.result.settings?.numberOfShiftsOnSaturday);
        setIsSameShift(unitResponse.result.settings?.isSameShiftsOnWeekends);
        if (unitResponse.result.settings?.isSameShiftsOnWeekends) {

          const weekendSlots = unitResponse.result.settings.saturdayShiftSlots?.map(slot => {
            const [startTime, endTime] = slot.split("-");
            return { startTime, endTime };
          });
          setWeekendShiftSlots(weekendSlots);
          setSaturdayShiftsSlots(weekendSlots);
          setSundayShiftsSlots(weekendSlots);

        } else {
          const sundaySlots = unitResponse.result.settings.sundayShiftSlots?.map(slot => {
            const [startTime, endTime] = slot.split("-");
            return { startTime, endTime };
          });
          // console.log(sundaySlots);
          setSundayShiftsSlots(sundaySlots);

          const saturdaySlots = unitResponse.result.settings.saturdayShiftSlots?.map(slot => {
            const [startTime, endTime] = slot.split("-");
            return { startTime, endTime };
          });
          setSaturdayShiftsSlots(saturdaySlots);
        }
        setNonProductiveDayDuration({
          startDate: unitResponse.result.settings?.nonProductiveDayDuration?.startDate || null,
          endDate: unitResponse.result.settings?.nonProductiveDayDuration?.endDate || null
        });
        setFirstDayOfMonth(unitResponse.result.settings?.firstDayOfMonth || false);
        setEsTarget(unitResponse.result.settings?.esTarget || false);
        setEsTargetReference(unitResponse.result.settings?.esTargetReference || "");
        setExporterToEurope(unitResponse.result.settings?.exporter || false);
        setIsCBAMApplicable(unitResponse.result.settings?.isCBAMApplicable || false);
        setIsBRSRMandatory(unitResponse.result.settings?.isBRSRMandatory || false);
        const planForLoadEnhancement = unitResponse.result.settings?.planForLoadEnhancement;
        console.log(planForLoadEnhancement);
        if (planForLoadEnhancement) {
          function parseDate(monthYear) {
            const [month, year] = monthYear.split('/');
            return new Date(year, month - 1);
          }
          const startDate = parseDate(planForLoadEnhancement);
          setStartDate(startDate);
        }
        // setPlanForLoadEnhancement(unitResponse.result.settings?.planForLoadEnhancement)
        setLoadModificationExisting(unitResponse.result.settings?.loadModificationExisting || false)
        setLoadAdditionNewPremises(unitResponse.result.settings?.loadAdditionNewPremises || false)
        setLoadType(unitResponse.result.settings?.loadType)
        setCapacityOfLoad(unitResponse.result.settings?.capacityOfLoad)
        setOperatingHours({
          weekDays: unitResponse.result.settings?.operatingHours?.weekDays,
          weekEnd: unitResponse.result.settings?.operatingHours?.weekEnd
        })
        if (unitResponse.result.settings?.holidayList?.length > 0) {
          setHolidayList(unitResponse.result.settings?.holidayList);
        }
        if (unitResponse.result?.settings?.maintenanceDateAndDuration?.length > 0) {
          const formattedDateTimeFields =
            unitResponse.result?.settings?.maintenanceDateAndDuration?.map(
              ({ date, time }) => {
                const [startTime, endTime] = time.split("-");
                return { date, startTime, endTime };
              }
            );
          setDateTimeFields(formattedDateTimeFields);
        }
      }
    };
    fetchData().catch(console.error);
  }, [id]);

  function capitalizeSentence(sentence) {
    const words = sentence.split(" ");
    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });
    return capitalizedWords.join(" ");
  }


  const isEmpty = value => {
    if (Array.isArray(value)) {
      return value.length === 0 || value.every(isEmpty);
    } else if (typeof value === 'object' && value !== null) {
      return Object.values(value).every(isEmpty);
    } else {
      return value === undefined || value === null || value === '' || value === '-';
    }
  };

  const cleanData = (data) => {
    if (typeof data !== 'object' || data === null) return data;
    if (Array.isArray(data)) {
      return data.filter(item => !isEmpty(item)).map(cleanData);
    }
    const cleanedData = Object.entries(data)
      .filter(([key, value]) => !isEmpty(value))
      .reduce((acc, [key, value]) => {
        acc[key] = cleanData(value);
        return acc;
      }, {});
    return cleanedData;
  };

  // Filter dateTimeFields to include only those with valid startTime and endTime
  const validDateTimeFields = dateTimeFields.filter(
    ({ startTime, endTime }) => startTime && endTime
  );

  const handleVisualizationSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    // console.log({ errors });
    if (excelTimeBlock === "") {
      setEExcelTimeBlock("Excel Time block cant be empty");
      return false;
    }
    const initialStateData = {
      buyerId: localStorage.getItem("userId"),
      excelTimeSlot: parseInt(excelTimeBlock),
      holidayList: holidayList,
      numberOfWeekdayShifts: parseInt(numberOfWeekdayShifts),
      weekdayShiftSlots: weekdayShiftSlots.map(slot => `${slot.startTime}-${slot.endTime}`),
      isSameShiftsOnWeekends: isSameShift,
      numberOfShiftsOnSunday: parseInt(numberOfShiftsOnSunday),
      sundayShiftSlots: (weekendShiftSlots.length > 0 && weekendShiftSlots[0].startTime !== "" && weekendShiftSlots[0].endTime !== "") ? weekendShiftSlots?.map(slot => `${slot.startTime}-${slot.endTime}`) : sundayShiftsSlots?.map(slot => `${slot.startTime}-${slot.endTime}`),
      numberOfShiftsOnSaturday: parseInt(numberOfShiftsOnSaturday),
      saturdayShiftSlots: (weekendShiftSlots.length > 0 && weekendShiftSlots[0].startTime !== "" && weekendShiftSlots[0].endTime !== "") ? weekendShiftSlots?.map(slot => `${slot.startTime}-${slot.endTime}`) : saturdayShiftsSlots?.map(slot => `${slot.startTime}-${slot.endTime}`),
      isSameShift: isSameShift,
      firstDayOfMonth: firstDayOfMonth,
      isBRSRMandatory: isBRSRMandatory,
      isCBAMApplicable: isCBAMApplicable,
      esTarget: esTarget,
      esTargetReference: esTargetReference,
      exporter: exporterToEurope,
      maintenanceDateAndDuration: validDateTimeFields.map(
        ({ date, startTime, endTime }) => {
          const start = new Date(`2000-01-01T${startTime}`);
          const end = new Date(`2000-01-01T${endTime}`);
          const timeDiff = end - start;
          const minutes = Math.floor(timeDiff / 60000);
          return {
            date: date,
            time: `${startTime}-${endTime}`,
            durations: minutes,
          };
        }
      ),
    };

    // Clean the data except nonProductiveDayDuration
    let allStateData = cleanData(initialStateData);

    // Append nonProductiveDayDuration to the cleaned data
    if (nonProductiveDayDuration.startDate && nonProductiveDayDuration.endDate) {
      const toUTCString = (date) =>
          date
              ? new Date(Date.UTC(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate())).toISOString()
              : null;
      allStateData.nonProductiveDayDuration = {
          startDate: toUTCString(nonProductiveDayDuration.startDate),
          endDate: toUTCString(nonProductiveDayDuration.endDate),
      };
  }
  
    console.log(allStateData);
    const response = await updateData(
      `${process.env.REACT_APP_API_URL}/unit/${id}`,
      allStateData
    );
    console.log(response);
    if (response?.data?.statusCode === 200) {
      toast.success("Unit setting custom visualization Successfully Updated", {
        position: "top-center",
        autoClose: 3000,
      });
      // navigate("/my-data");
      setCurrentStep(prevStep => prevStep + 1);
    } else {
      toast.error(response.error.response.data.error.message);
    }

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    const initialStateData = {
      buyerId: localStorage.getItem("userId"),
      planForLoadEnhancement: formatDateToString(startDate),
      loadModificationExisting: loadModificationExisting,
      loadAdditionNewPremises: loadAdditionNewPremises,
      loadType: loadType,
      capacityOfLoad: capacityOfLoad,
      operatingHours: operatingHours,
    };
    let allStateData = cleanData(initialStateData);

    console.log(allStateData);
    const response = await updateData(
      `${process.env.REACT_APP_API_URL}/unit/${id}`,
      allStateData
    );
    console.log(response);
    if (response?.data?.error?.code === 200) {
      toast.success("Unit setting feature load Successfully Updated", {
        position: "top-center",
        autoClose: 3000,
      });
      // navigate("/my-data");
      handleCancel();
    } else {
      // toast.error(response.error.response.data.error.message);
    }

  };

  const handleErrors = (errorObj) => {
    // console.log(errorObj);

    const key = Object.keys(errorObj)[0];
    const value = errorObj[key];

    const existingIndex = errors.findIndex(
      (error) => Object.keys(error)[0] === key
    );

    if (existingIndex !== -1) {
      setFormSubmitted(true);
      const updatedErrors = [...errors];
      updatedErrors[existingIndex][key] = value;
      setErrors(updatedErrors);
    } else {
      setErrors((prevErrors) => [...prevErrors, { [key]: value }]);
    }
  };

  const addHoliday = () => {
    setAddHolidayError("");
    // console.log(holidayList);
    const lastHoliday = holidayList[holidayList.length - 1];
    if (lastHoliday.date !== "" && lastHoliday.note !== "") {
      setHolidayList([...holidayList, { date: "", note: "" }]);
      setAddHolidayError("");
    } else {
      setAddHolidayError("Date and Note both fields should be filled");
    }
  };

  const removeHoliday = (index) => {
    const newList = [...holidayList];
    newList.splice(index, 1);
    setHolidayList(newList);
  };

  const handleStartTimeChange = (index, newValue) => {
    const newSlots = [...timeSlots];
    newSlots[index].startTime = newValue;
    setTimeSlots(newSlots);
  };

  const handleEndTimeChange = (index, newValue) => {
    const newSlots = [...timeSlots];
    newSlots[index].endTime = newValue;
    setTimeSlots(newSlots);
  };

  const handleMainStartTimeChange = (time, index) => {
    const updatedFields = [...dateTimeFields];
    updatedFields[index].startTime = time;
    setDateTimeFields(updatedFields);
  };

  const handleMainEndTimeChange = (time, index) => {
    const updatedFields = [...dateTimeFields];
    updatedFields[index].endTime = time;
    setDateTimeFields(updatedFields);
  };

  const handleAddField = () => {
    const lastField = dateTimeFields[dateTimeFields.length - 1];
    // Check if the last field's date or time is empty
    if (!lastField.date || !lastField.startTime || !lastField.endTime) {
      // If empty, show error and return
      setErrorMaintainance(
        "Please fill in the date, start time, and end time for the last field."
      );
      return;
    }
    // Clear any existing error
    setErrorMaintainance("");
    // Add the new field
    setDateTimeFields([
      ...dateTimeFields,
      { date: "", startTime: "", endTime: "" },
    ]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...dateTimeFields];
    updatedFields.splice(index, 1);
    setDateTimeFields(updatedFields);
  };

  const handleDateChange = (date, index) => {
    let formattedDate;
    if (date) {
      formattedDate = date.toISOString();
    }
    const updatedFields = [...dateTimeFields];
    updatedFields[index].date = formattedDate;
    setDateTimeFields(updatedFields);
  };

  useEffect(() => {
    console.log({ orgName, factoryName });
  }, [orgName, factoryName]);
  const handleCheckboxChange = () => {
    setIsSameShift(!isSameShift); // Toggle the value of isSameShift
  };


  useEffect(() => {
    console.log({ orgName, factoryName });
  }, [weekendShiftSlots]);

  const handlePrev = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const hourOptions = [];
  for (let i = 0; i < 24; i++) {
    hourOptions.push({ value: i, label: `${i} hours` });
  }

  const handleWeekdayShiftHours = (index, value) => {
    const newWeekDays = [...operatingHours.weekDays];
    newWeekDays[index] = Number(value);
    console.log(newWeekDays);
    setOperatingHours(prev => ({ ...prev, weekDays: newWeekDays }));
  };

  const handleWeekendShiftHours = (index, value) => {
    const newWeekEnd = [...operatingHours.weekEnd];
    newWeekEnd[index] = Number(value);
    console.log(newWeekEnd);

    setOperatingHours(prev => ({ ...prev, weekEnd: newWeekEnd }));
  };

  const handleCancel = () => {
    navigate(-1);
  };


  const [startDate, setStartDate] = useState();
  const [selectedRange, setSelectedRange] = useState({
    start: null,
  });

  const formatDateToString = (date) => {
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const year = date?.getFullYear()?.toString();
    return `${month}/${year}`;
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setSelectedRange({
      start: formatDateToString(date),
    });
  };

  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[100px] pb-20 custom-form">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      {currentStep === 0 &&
        <div className={`${isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"} `}>
          <div className=" rounded-lg  sm:bg-[#E2F5EC] sm:pl-[40px] w-full flex flex-col pb-[50px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">

            <div className="text-[30px] pt-8  text-left font-medium leading-[42px] ">
            Customise units
            </div>
            <div className="text-gray-600 py-2 capitalize text-[11px]">
              {" "}
              <span className="text-gray-400 capitalize">{orgName} </span>
              <span>(org) </span>
              &ensp;
              <FontAwesomeIcon
                icon={faForward}
                className=" text-gray-300 text-sm font-medium"
              />
              &ensp;
              <span className="text-gray-400 capitalize">{factoryName} </span>
              <span>(factory) </span>
              &ensp;
              <FontAwesomeIcon
                icon={faForward}
                className=" text-gray-300 text-sm font-medium"
              />
              &ensp;
              <span className="text-gray-400 capitalize">{name} </span>
              <span>(unit) </span>
            </div>
            <div className=" w-full mt-7">
              {" "}
              <ASelectv2
                label={"1. Date timeblocks by 15 Mins or hourly?"}
                isflexed={true}
                // validationtypes={"required"}
                imputBoxMaxWidth={545}
                // showError={formSubmitted}
                fieldName={"Duration"}
                showLable={true}
                value={excelTimeBlock}
                onChange={(e) => {
                  setExcelTimeBlock(e.target.value);
                }}
                options={[
                  { label: "15 Minutes", value: "15" },
                  { label: "30 Minutes", value: "30" },
                  { label: "45 Minutes", value: "45" },
                  { label: "60 Minutes", value: "60" },
                ]}
              // handleErrors={handleErrors}
              />
              {eExcelTimeBlock ? (
                <div className="text-red-500  text-[9px] capitalize px-[20px] py-[10px]">
                  {eExcelTimeBlock}
                </div>
              ) : (
                ""
              )}
            </div>
            <br />

            <div className="w-full pl-[20px] mt-6">
              <div className="text-xl leading-[21px] flex flex-col sm:flex-row sm:items-center w-full">
                <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">2. How many shifts in a weekday?</div>
                <div>
                  <select
                    value={numberOfWeekdayShifts}
                    onChange={(e) => setNumberOfWeekdayShifts(e.target.value)}
                    className="border border-gray-300 rounded-[10px] h-[30px] px-3 mt-4 sm:mt-0 mr-4 focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value={1}>1 shift</option>
                    <option value={2}>2 shifts</option>
                    <option value={3}>3 shifts</option>
                  </select>
                </div>
              </div>
              <div className="w-full pl-[20px]">
                {numberOfWeekdayShifts > 0 ? <>
                  <ShiftSlotSelector
                    shiftType="Weekday"
                    selectedSlots={weekdayShiftSlots}
                    setSelectedSlots={setWeekdayShiftSlots}
                    numberOfShifts={numberOfWeekdayShifts}
                  />
                </>
                  : ""}

              </div>
            </div>
            <br />
            <div className="w-full mt-6">
              <div className="flex flex-row items-center pl-[20px]">
                <div className='font-normal text-[#323232] text-[20px] leading-[21px]'>3. Is same shifts on Saturday and Sunday?</div>
                <input
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                  checked={isSameShift}
                  onChange={handleCheckboxChange}
                  // className=""
                  className="scale-checkbox w-[50px]"
                />
              </div>
              {
                isSameShift ?
                  <>
                    <div className="text-xl leading-[21px] flex flex-row items-center mt-7 w-full ml-[20px]">
                      <div className="text-xl leading-[21px] flex flex-col sm:flex-row sm:items-center pl-[20px] w-full">
                        <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">A. How many shifts in a Saturday/Sunday?</div>
                        <div>
                          <select
                            value={numberOfWeekEndShifts}
                            onChange={(e) => {
                              setNumberOfWeekEndShifts(e.target.value);
                              setNumberOfShiftsOnSaturday(e.target.value);
                              setNumberOfShiftsOnSunday(e.target.value);
                            }}
                            className="border border-gray-300 rounded-[10px] h-[30px] px-3 mt-4 sm:mt-0 mr-4 focus:outline-none focus:ring focus:border-blue-300"
                          >
                            <option value="">Select</option>
                            <option value={1}>1 Shift</option>
                            <option value={2}>2 Shifts</option>
                            <option value={3}>3 Shifts</option>
                            <option value={4}>4 Shifts</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="w-full pl-[20px] mt-1 ml-[20px]">
                      {numberOfWeekEndShifts > 0 ? <>
                        <ShiftSlotSelector
                          shiftType="WeekEnd"
                          selectedSlots={weekendShiftSlots}
                          setSelectedSlots={setWeekendShiftSlots}
                          numberOfShifts={numberOfWeekEndShifts}
                        />
                      </>
                        : ""}

                    </div>
                  </>
                  :
                  <>
                    <div className="text-xl leading-[21px] flex flex-row items-center mt-7 w-full ml-[20px]">
                      <div className="text-xl leading-[21px] flex flex-row items-center pl-[20px] w-full">
                        <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">A. How many shifts in a Saturday?</div>
                        <div>
                          <select
                            value={numberOfShiftsOnSaturday}
                            onChange={(e) => {
                              setNumberOfShiftsOnSaturday(e.target.value);
                            }}
                            className="border border-gray-300 rounded-[10px] h-[30px] px-3  mr-4 focus:outline-none focus:ring focus:border-blue-300"
                          >
                            <option value="">Select</option>
                            <option value={1}>1 Shift</option>
                            <option value={2}>2 Shifts</option>
                            <option value={3}>3 Shifts</option>
                            <option value={4}>4 Shifts</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="w-full pl-[20px] mt-1 ml-[20px]">
                      {numberOfShiftsOnSaturday > 0 ? <>
                        <ShiftSlotSelector
                          shiftType="Saturday"
                          selectedSlots={saturdayShiftsSlots}
                          setSelectedSlots={setSaturdayShiftsSlots}
                          numberOfShifts={numberOfShiftsOnSaturday}
                        />
                      </>
                        : ""}

                    </div>
                    <div className="text-xl leading-[21px] flex flex-row items-center mt-7 w-full ml-[20px]">
                      <div className="text-xl leading-[21px] flex flex-row items-center pl-[20px] w-full">
                        <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">B. How many shifts in a Sunday?</div>
                        <div>
                          <select
                            value={numberOfShiftsOnSunday}
                            onChange={(e) => {
                              setNumberOfShiftsOnSunday(e.target.value);
                            }}
                            className="border border-gray-300 rounded-[10px] h-[30px] px-3 mr-4 focus:outline-none focus:ring focus:border-blue-300"
                          >
                            <option value="">Select</option>
                            <option value={1}>1 Shift</option>
                            <option value={2}>2 Shifts</option>
                            <option value={3}>3 Shifts</option>
                            <option value={4}>4 Shifts</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="w-full pl-[20px] mt-1 ml-[20px]">
                      {numberOfShiftsOnSunday > 0 ? <>
                        <ShiftSlotSelector
                          shiftType="Sunday"
                          selectedSlots={sundayShiftsSlots}
                          setSelectedSlots={setSundayShiftsSlots}
                          numberOfShifts={numberOfShiftsOnSunday}
                        />
                      </>
                        : ""}

                    </div>
                  </>
              }
            </div>

            <br />

            <div className="w-full">
              <div className="text-xl leading-[21px] flex flex-col mt-7 w-full pl-[20px]">
                <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5">4.What is date range for Non-Productive Days?</div>
                <div className="non-productive mt-3">
                  <DatePicker
                    startDate={nonProductiveDayDuration.startDate}
                    endDate={nonProductiveDayDuration.endDate}
                    selectsRange={true}
                    onChange={(dates) => handleDateRangeChange(dates)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Date Range"
                    className="border border-gray-300 ml-1 rounded-[10px] h-[30px] px-3 mr-4 focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>
              </div>
            </div>

            <br />

            {/* firstDayOfMonth */}
            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">5. Is first day of month is non-productive or not?</div>

              <select
                id="firstDayOfMonth"
                value={firstDayOfMonth}
                onChange={(e) => setFirstDayOfMonth(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3  mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>

            {/* esTarget */}
            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5  w-[75%]">6. Is ES Target?</div>

              <select
                id="esTarget"
                value={esTarget}
                onChange={(e) => setEsTarget(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3  mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>

            {/* esTargetReferance */}
            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5  w-[75%]">7. What is ES Target Reference?</div>

              <select
                id="esTargetReferance"
                value={esTargetReference}
                onChange={(e) => setEsTargetReference(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3  mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value="SBT">SBT</option>
                <option value="GRI">GRI</option>
                <option value="CDP">CDP</option>
                <option value="RELW">RELW</option>
                <option value="Other">Other</option>
                <option value="None">None</option>
              </select>
            </div>

            {/* exporterToEurope */}
            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5  w-[75%]">8. Are you exporter to Europe/ US?</div>

              <select
                id="exporterToEurope"
                value={exporterToEurope}
                onChange={(e) => setExporterToEurope(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3  mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>

            {/* isCBAMApplicable */}
            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5  w-[75%]">9. Is CBAM applicable to you?</div>

              <select
                id="isCBAMApplicable"
                value={isCBAMApplicable}
                onChange={(e) => setIsCBAMApplicable(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3  mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>

            {/* isBRSRMandatory */}

            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5  w-[75%]">10. Is BRSR Reporting Mandatory?</div>
              <select
                id="isBRSRMandatory"
                value={isBRSRMandatory}
                onChange={(e) => setIsBRSRMandatory(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3  mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>

            <div className="w-full pl-[20px] mt-7">
              <AddHoliday
                holidayList={holidayList}
                addHoliday={addHoliday}
                removeHoliday={removeHoliday}
                setHolidayList={setHolidayList}
                addHolidayError={addHolidayError}
              />
            </div>
            <div className="w-full pl-[20px] mt-7">
              <div>
                <div className="font-medium text-[#323232] text-[20px] leading-[21px] mb-4">
                  12. Planned Shutdowns or Maintenance Activities
                </div>
                {dateTimeFields.map((field, index) => (
                  <div
                    key={index}
                    className="flex flex-col sm:flex-row sm:items-center maintainance mt-4"
                  >
                    <div className="w-[50%] sm:w-[30%] relative">
                      <DatePicker
                        // value={field.date}
                        selected={field.date ? new Date(field.date) : null}
                        onChange={(date) => handleDateChange(date, index)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                      />
                      <div className="absolute right-0 top-2 mt-[10px] mr-[10px]">
                        <div>
                          <img
                            src="/calenderIcon.svg"
                            className="h-[18px] w-[18px]"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-3 sm:my-0 sm:ml-4">
                      <TimePicker
                        value={field.startTime || "00:00"}
                        onChange={(time) =>
                          handleMainStartTimeChange(time, index)
                        }
                        format="h:m: a"
                        disableClock={true}
                        clearIcon={null}
                      />
                    </div>
                    <div className="sm:mx-2">
                      <TimePicker
                        value={field.endTime || "12:00"}
                        onChange={(time) => handleMainEndTimeChange(time, index)}
                        format="h:m: a"
                        disableClock={true}
                        clearIcon={null}
                      />
                    </div>
                    <div className="flex flex-row items-center">
                      <div>
                        {dateTimeFields.length > 1 && (
                          <button
                            className="text-center rounded-[7px] font-medium leading-[24px]  h-[25px] w-[25px]  text-[16px] border border-[gray] text-[red] bg-[white] mx-[10px]"
                            onClick={() => handleRemoveField(index)}
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              className=" text-[red] text-sm font-medium"
                            />
                          </button>
                        )}
                      </div>
                      <div>
                        {index === dateTimeFields.length - 1 && (
                          <button
                            className="text-center rounded-[7px] text-[16px] font-medium leading-[24px] h-[25px] w-[25px] text-white bg-[#067C4E] mx-[10px]"
                            onClick={handleAddField}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              className=" text-[white] text-sm font-medium"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {errorMaintainance ? (
                <div className="text-red-500  text-[9px] capitalize px-[20px] py-[10px]">
                  {errorMaintainance}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mt-[33px] w-[90%] px-[14px] sm:px-[55px] sm:mt-[70px] flex flex-row font-normal justify-center gap-[20px]">
              <Abutton
                handleOnclick={handleVisualizationSubmit}
                text={"Next"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={200}
              />
              <div onClick={handleCancel}>
                <Abutton
                  text={"Cancel"}
                  Mheight={44}
                  Mwidth={100}
                  DHeight={44}
                  DWidth={200}
                />
              </div>
            </div>
          </div>
        </div>
      }


      {currentStep === 1 &&
        <div className={`${isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"} `}>
          <div className=" rounded-lg  sm:bg-[#E2F5EC] sm:pl-[40px] w-full flex flex-col pb-[50px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">
            <div className="text-[30px] sm:pt-8  text-left font-medium leading-[42px] sm:mt-4">
              Feature Load Profile
            </div>

            <div className="flex flex-col sm:flex-row sm:items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] sm:mr-5 w-[75%]">1. Plan for Load Enhancement :</div>
              <div className="items-center month-year-picker font-light text-[#9b9898] sm:mr-4 mt-2 sm:mt-0">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="MMM-yyyy"
                  showMonthYearPicker
                  wrapperClassName="month-picker-wrapper"
                  placeholderText="Start Month"
                  minDate={new Date()}
                />
              </div>
            </div>


            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">2. Load Modification – Existing :</div>
              <select
                value={loadModificationExisting}
                onChange={(e) => setLoadModificationExisting(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3 w-[30%] mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>

            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">3. Load Addition- new premises :</div>
              <select
                value={loadAdditionNewPremises}
                onChange={(e) => setLoadAdditionNewPremises(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3 w-[30%] mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>

            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <div className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">4. Load type :</div>
              <select
                value={loadType}
                onChange={(e) => setLoadType(e.target.value)}
                className="border border-gray-300 rounded-[10px] h-[30px] px-3 w-[30%] mr-4 focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select</option>
                <option value="addition">Addition</option>
                <option value="subtraction">Subtraction</option>
              </select>
            </div>

            <div className="flex flex-row items-center mt-7 w-full pl-[20px]">
              <label htmlFor="capacityOfLoad" className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">5. What is the capacity of load? (units)</label>
              <input
                type="number"
                id="capacityOfLoad"
                value={capacityOfLoad}
                onChange={(e) => setCapacityOfLoad(e.target.value)}
                placeholder="Enter capacity of load"
                className="border border-gray-300 rounded-[10px] h-[30px] px-3 mr-4 w-[30%] focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>



            <div className="pl-[20px]">
              <div className="flex flex-row mt-7 w-full">
                <label htmlFor="weekdayShifts" className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">
                  6. Set Operating Hours for Weekday Shifts :
                </label>
                <div className="w-[30%] mr-4 mt-[-13px]">
                  {Array.from({ length: numberOfWeekdayShifts }).map((_, index) => (
                    <select key={index}
                      value={operatingHours.weekDays[index]}
                      onChange={(e) => handleWeekdayShiftHours(index, e.target.value)}
                      className="border border-gray-300 rounded-[10px] h-[30px] px-3 mr-4 w-full mt-2 focus:outline-none focus:ring focus:border-blue-300">
                      {hourOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  ))}
                </div>
              </div>

              {(numberOfWeekEndShifts || numberOfShiftsOnSaturday || numberOfShiftsOnSunday) ?
                <div className="flex flex-row mt-7 w-full">
                  <label htmlFor="weekendShifts" className="font-medium text-[#323232] text-[20px] leading-[21px] mr-5 w-[75%]">
                    7. Set Operating Hours for Weekend Shifts :
                  </label>
                  <div className="w-[30%] mr-4 mt-[-13px]">
                    {Array.from({ length: numberOfWeekEndShifts || numberOfShiftsOnSaturday || numberOfShiftsOnSunday }).map((_, index) => (
                      <select key={index}
                        value={operatingHours.weekEnd[index]}
                        onChange={(e) => handleWeekendShiftHours(index, e.target.value)}
                        className="border border-gray-300 rounded-[10px] h-[30px] px-3 mr-4 w-full mt-2 focus:outline-none focus:ring focus:border-blue-300">
                        {hourOptions.map(option => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    ))}
                  </div>
                </div>
                : ""}

            </div>

            <div className="mt-[33px] w-[90%] px-[14px] sm:px-[55px] sm:mt-[70px] flex flex-row font-normal justify-center gap-[20px]">
              <Abutton
                handleOnclick={handlePrev}
                text={"Prev"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={200}
              />
              <Abutton
                handleOnclick={handleSubmit}
                text={"Save & Next"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={200}
              />
              <div onClick={handleCancel}>
                <Abutton
                  text={"Cancel"}
                  Mheight={44}
                  Mwidth={100}
                  DHeight={44}
                  DWidth={200}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default UpdateUnitSetting;
