import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment/moment";
import GoTODashboard from "../../components/button/GoTODashboard";
import { fetchNotificationList } from "../../api/apiDataExchange";
import { AlertUtils } from "../../utils/alertUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import { MultiSelect } from "react-multi-select-component";
import { getUserOrgId } from "../../utils/authenticationUtils";

const Notifications = () => {
  const alertUtil = new AlertUtils();
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [notificationList, setNotificationList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    localStorage.getItem("pageSize") || 5
  );
  const [totalCount, setTotalCount] = useState(null);
  const [search, setSearch] = useState(false);
  const [limit, setLimit] = useState(() => {
    const storedLimit = parseInt(localStorage.getItem("recordsPerPage"), 10);
    return storedLimit || 10;
  });

  const [filters, setFilters] = useState({
    organisationId: "",
    startDate: "",
    endDate: "",
    type: [],
    status: "active",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortConfig, setSortConfig] = useState({});
  const [tableHeight, setTableHeight] = useState(0);
  const [selectedAlertType, setSelectedAlertType] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [orgID, setOrgID] = useState(null);
  const [loader, setLoader] = useState(false);
  const [alerts, setAlerts] = useState([]);
  useEffect(() => {}, [tableHeight]);

  const calculateHeight = () => {
    const windowHeight = window.innerHeight - 400;
    const availableHeight = windowHeight;
    setTableHeight(availableHeight);
  };

  useEffect(() => {

    if( alertUtil.alertTemplates ){
        let alert_list = [];

        for (let alert_type in alertUtil.alertTemplates) {
          // code block to be executed
          alert_list.push({
            "label" : alertUtil.alertTemplates[alert_type].alertName,
            "value" : alert_type
          });
        }

        setAlerts(alert_list);
    }

    

    calculateHeight();
    window.addEventListener("resize", calculateHeight);
    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  const [tableHeaders, setTableHeaders] = useState({
    name: "Alert Name",
    createdAt: "Alert Date Time",
    billDate: "Bill Date",
    consumerNo: "Consumer No",
    address: "Address",
    alertDescription: "Alert description",
    action: "Action",
  });
  /*
  const alerts = [
    { value: "incentive-earned-alert", label: "Incentive Earned Alert" },
    { value: "missed-incentive-alert", label: "Missed Incentive Alert" },
    { value: "penalty-incurred-alert", label: "Penalty Incurred Alert" },
    {
      value: "repeated-late-payments-alert",
      label: "Repeated Late Payments Alert",
    },
    {
      value: "low-load-factor-alert",
      label: "Low Load Factor Detected!",
    },
  ];
  */

  useEffect(() => {
    const getdata = async () => {
      const orgId = await getUserOrgId();
      setOrgID(orgId);

      if (orgId) {
        const payload = {
          ...filters,
          organisationId: orgId,
          sortBy: sortConfig.key,
          sortOrder: sortConfig.direction === "asc" ? "asc" : "desc",
          page: currentPage,
          pageSize: limit,
        };
        setLoader(true);
        const response = await fetchNotificationList(payload);
        if (response.statusCode === 200) {
          setNotificationList(response.data.results);
          setTableData(response.data.results);
          setTotalCount(response.data["total"]);
          setTotalPages(Math.ceil(response.data["total"] / pageSize) || 1);
          setSearch(false);
        }
        setTimeout(() => {
            setLoader(false);
        }, 1500);
    
      }
    };
    getdata();
  }, [currentPage, sortConfig, search]);

  useEffect(() => {
    if (currentPage * limit >= totalCount && totalCount !== null) {
      setCurrentPage(1);
    }
  }, [totalCount]);

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    handleSearch();
  };

  const handleSetPageLimit = (event) => {
    setLimit(Number(event.target.value));
    localStorage.setItem("recordsPerPage", event.target.value);
    handleSearch();
  };

  const formateDatetoMonth = (dateString) => {
    return moment(dateString).format("DD/MM/YYYY");
  };

  const handleSearch = async () => {
    let updatedFilters = {};
    if (orgID) {
      updatedFilters.organisationId = orgID;
    }
    if (startDate) {
      updatedFilters.startDate = formateDatetoMonth(startDate);
    }
    if (endDate) {
      updatedFilters.endDate = formateDatetoMonth(endDate);
    }
    if (selectedAlertType) {
      updatedFilters.type = selectedAlertType.map((item) => item.value);
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedFilters,
    }));

    setSearch(true);
  };

  const handleReset = () => {
    handleSearch();
    setStartDate("");
    setEndDate("");
    setSelectedAlertType([]);
    setSelectedStatus([]);
    setSortConfig({});
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: "active",
      startDate: "",
      endDate: "",
      type: [],
    }));
    console.log({ filters });
  };

  const renderSortArrow = (key) => {};

  const handleAlertChange = (selected) => {
    const updatedSelection = Array.isArray(selected) ? selected : [selected];
    setSelectedAlertType(updatedSelection);
  };

  const handleView = (conNo, startDate, endDate) => {
    window.open(
      `/compare-ocr-bills?conNo=${conNo}&startDate=${startDate}&endDate=${endDate}&redirect=alerts`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="max-w-[1920px] relative mt-[70px] flex ">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div
        className={`${
          isSidebarOpen ? "sm:pl-[315px]" : "sm:pl-[95px]"
        } w-[100%] px-2 mb-8 mr-1 transition-width duration-300`}
      >
        <div className=" rounded-lg pb-[5px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:mt-[30px]">
          <div className="flex flex-row justify-between">
            <div className="px-4 pt-4 pb-2 text-[20px] sm:text-[28px] font-medium leading-[42px]">
              Alerts List
            </div>
            <div className="flex gap-2  h-[60px] sm:h-auto sm:leading-[42px] py-2 pr-0 sm:pr-2 ">
              <GoTODashboard />
            </div>
          </div>
          <div className="flex flex-wrap gap-1 px-2 justify-between">
            <div className="flex sm:w-[60%] lg:w-[60%] gap-2">
              <div className=" rounded-[10px] outline-none border-gray-800 text-black sm:w-[40%] lg:w-[30%]">
                <MultiSelect
                  options={alerts}
                  value={selectedAlertType}
                  onChange={handleAlertChange}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: "Select alerts",
                    allItemsAreSelected: "All alerts selected",
                  }}
                  disableSearch={false}
                  className="custom-multi-select"
                />
              </div>
            </div>

            <div className="flex flex-row tem-center">
              <div className="flex justify-end">
                <button
                  onClick={handleSearch}
                  className="text-white bg-[#067C4E] hover:bg-[#050036] w-[100px] rounded-lg"
                >
                  Filter
                </button>
                <button
                  onClick={handleReset}
                  className="bg-white border-[#067C4E] hover:bg-gray-200 text-[#067C4E] border-2 border-solid w-[100px] ml-3 rounded-lg"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {true ? (
            <div>
              <div className="w-full relative">
                <div className={`overflow-x-auto  `}>
                  <table className="w-full mt-2 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] border-[#EFEFEF] text-center ">
                    <thead className="bg-[#EFEFEF] sticky top-0 z-10">
                      <tr>
                        {Object.entries(tableHeaders)?.map(
                          ([key, header], index) => {
                            let widthClass = "";
                            if (index === 0) {
                              widthClass = "w-[10%]";
                            } else if (index === 1) {
                              widthClass = "w-[12%]";
                            } else if (index === 2) {
                              widthClass = "w-[10%]";
                            } else if (index === 3) {
                              widthClass = "w-[10%]";
                            } else if (index === 4) {
                              widthClass = "w-[15%]";
                            } else if (index === 5) {
                              widthClass = "w-[35%]";
                            } else if (index === 6) {
                              widthClass = "w-[10%]";
                            }
                            return (
                              <th
                                key={index}
                                className={`cursor-pointer font-medium ${widthClass} px-4 py-2`}
                                onClick={() => sortData(key)}
                              >
                                {header} {renderSortArrow(key)}
                              </th>
                            );
                          }
                        )}
                      </tr>
                    </thead>
                  </table>
                </div>

                <div
                  style={{ height: `${tableHeight}px` }}
                  className={`overflow-y-auto upload-bills  `}
                >
                  <table className="w-full mt-2 text-center">
                    <tbody>
                      {tableData?.length === 0 && !loader ? (
                        <tr>
                          <td
                            colSpan={Object.keys(tableData).length}
                            className="px-6 py-4 text-sm leading-5 text-gray-500 text-center"
                          >
                            No active alerts available
                          </td>
                        </tr>
                      ): loader ? (
                        <tr>
                          <td
                            colSpan={Object.keys(tableData).length}
                            className="px-6 py-4 text-sm leading-5 text-gray-500 text-center"
                          >
                                            <LoaderComponent width={"60"} height={"60"} />

                          </td>
                        </tr>
                      ): (
                        tableData?.map((rowData, rowIndex) => {
                          let billDate = new Date(
                            rowData?.powerConsumptionId?.billMonthOfLastDay
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                          });

                          return (
                            <tr
                              key={rowIndex}
                              className="h-[50px] text-gray-800 font-thin"
                            >
                              {Object.entries(tableHeaders)?.map(
                                ([key, header], cellIndex) => {
                                  const alertType = rowData["type"]; // Assuming "type" holds the key for the alert type
                                  let value = rowData[key] || ""; // Default value is an empty string
                                  if (key === "name") {
                                    try {
                                      const alertData =
                                        alertUtil.alertTemplates[alertType];
                                      value = alertData?.alertName || "-";
                                    } catch (error) {
                                      console.error(error.message);
                                      value = "-";
                                    }
                                  }

                                  if (key === "createdAt") {
                                    value = new Date(
                                      rowData?.timestamp
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    });
                                  }

                                  if (key === "alertDescription") {
                                    let incentiveAmount =
                                      rowData?.powerConsumptionId
                                        ?.totalBillAmount?.value -
                                        rowData?.powerConsumptionId
                                          ?.ifPaidUptoAmount?.value || 0;
                                    let penaltyAmount =
                                      rowData?.powerConsumptionId
                                        ?.ifPaidAfterAmount?.value -
                                        rowData?.powerConsumptionId
                                          ?.totalBillAmount?.value || 0;

                                    let param = {
                                      consumerNumber:
                                      rowData?.powerConsumptionId?.meterId
                                          ?.consumerNo ?? "N/A",
                                      billDate: `${billDate}`,
                                      incentiveAmount:
                                        incentiveAmount?.toFixed(2) ?? "0.00",
                                      penaltyAmount:
                                        penaltyAmount?.toFixed(2) ?? "0.00",
                                      billMonth:
                                      rowData?.powerConsumptionId?.billMonth ??
                                        "N/A",
                                      amount:
                                      rowData?.powerConsumptionId
                                          ?.totalBillAmount?.value ?? 0,
                                      LF: rowData?.powerConsumptionId?.LF?.value
                                        ? rowData.powerConsumptionId.LF.value.toFixed(
                                            2
                                          )
                                        : "0.00",
                                      avgPF: rowData?.powerConsumptionId?.avgPF
                                        ?.value
                                        ? rowData.powerConsumptionId.avgPF.value.toFixed(
                                            2
                                          )
                                        : "0.00",
                                    };

                                    param = { ...param, ...rowData.data };

                                    try {
                                      
                                      // Use AlertUtils to fetch the dynamic alert
                                      const alertData = alertUtil.getAlert(
                                        alertType,
                                        param
                                      );

                                      value = alertData.message;
                                    } catch (error) {
                                      console.error(error.message);
                                      value = "-";
                                    }
                                  }

                                  if (key === "billDate") {
                                    value = billDate;
                                  }

                                  if (key === "consumerNo") {
                                    value =
                                      rowData?.["powerConsumptionId"]?.[
                                        "meterId"
                                      ]?.[key] || "-";
                                  }

                                  if (key === "address") {
                                    value =
                                      rowData?.["powerConsumptionId"]?.[
                                        "factoryId"
                                      ]?.[key] || "-";
                                  }

                                  if (
                                    key === "startDate" ||
                                    key === "endDate"
                                  ) {
                                    value = value
                                      ? formateDatetoMonth(value)
                                      : "";
                                  }

                                  let widthClass = "";
                                  if (cellIndex === 0) {
                                    widthClass = "w-[10%]"; // First column width 15%
                                  } else if (cellIndex === 1) {
                                    widthClass = "w-[12%] "; // Second column width 50%
                                  } else if (cellIndex === 2) {
                                    widthClass = "w-[10%]"; // Fourth column width 15%
                                  } else if (cellIndex === 3) {
                                    widthClass = "w-[10%]"; // Fourth column width 15%
                                  } else if (cellIndex === 4) {
                                    widthClass = "w-[15%]"; // Fourth column width 15%
                                  } else if (cellIndex === 5) {
                                    widthClass = "w-[35%]"; // Fourth column width 15%
                                  } else if (cellIndex === 5) {
                                    widthClass = "w-[10%]"; // Fourth column width 15%
                                  }

                                  if (key === "action") {
                                    let billMonthOfLastDay = new Date(
                                      rowData?.powerConsumptionId?.billMonthOfLastDay
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                    });

                                    return (
                                      <td
                                        key={cellIndex}
                                        className="p-1 w-[10%]"
                                      >
                                        {rowData?.powerConsumptionId
                                          ?.billUploadSoruce === "ocr" && (
                                          <button
                                            className="cursor-pointer text-[blue] underline px-3 py-1"
                                            onClick={() =>
                                              handleView(
                                                rowData.powerConsumptionId
                                                  .meterId.consumerNo,
                                                billMonthOfLastDay,
                                                billMonthOfLastDay
                                              )
                                            }
                                          >
                                            View Bill
                                          </button>
                                        )}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td
                                        key={cellIndex}
                                        className={`py-2 ${widthClass}`}
                                      >
                                        {value}
                                      </td>
                                    );
                                  }
                                }
                              )}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {tableData?.length !== 0 && (
                <div>
                  <div className="pagination-controls border-t-2 border-[#abaaaa] flex flex-row items-center justify-between pt-1">
                    <div className="mr-6">
                      <label htmlFor="itemsPerPage" className="mr-2 ml-2">
                        Items per page:
                      </label>
                      <select
                        id="itemsPerPage"
                        value={limit}
                        onChange={handleSetPageLimit}
                        className="px-2 py-1 rounded-lg border border-gray-300"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                    <div>
                      <button
                        className={`bg-[#e1e0e0] px-2 py-1 rounded-md`}
                        onClick={() => {
                          setCurrentPage(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="mx-4">
                        {totalCount > 0
                          ? `${(currentPage - 1) * limit + 1} to ${Math.min(
                              currentPage * limit,
                              totalCount
                            )} of ${totalCount}`
                          : "0"}
                      </span>

                      <button
                        className="bg-[#e1e0e0]  mr-2 px-2 py-1 rounded-md"
                        disabled={currentPage * limit >= totalCount}
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="min-h-[50vh] flex justify-center items-center">
              <LoaderComponent />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
